import axios from 'axios'

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  validateStatus: (status) => {
    return status >= 200 && status <= 500
  },
})

API.interceptors.request.use(
  (config) => {
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

// Add a response interceptor
API.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('API.interceptors.response - response:')
    if (response.status !== 200) {
      const errorMessage = response.data.message || response.data.msg
      console.error(`url:${response.request.responseURL} error`, `errorMessage:${errorMessage}`, `status code:${response.status}`)
    }
    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log('API.interceptors.response - error:')
    console.error(error)
    error.response && console.error(error.response.request.responseURL, error)
    return Promise.reject(error)
  },
)

// export const postLogin = (body) => API.post(`/signin?redirect=${process.env.REACT_APP_TAXIGO_CONSOLE}`, body)
export const postLogin = (body) => API.post(`/signin`, body)
export const getUser = () => API.get('/v2/admin/user')
export const getTrips = () => API.get('/getTrips/0')
export const getConfig = () => API.get(`/config`)

export const postTable = (table, body) => API.post(`/admin/tables/${table}`, body)
export const deleteTable = (table, id) => API.delete(`/admin/tables/${table}/${id}`)
export const putTable = (table, id, batch, body) => API.put(`/admin/tables/${table}/${id}${batch}`, body)
// campaign
export const getMktBroadcasting = () => API.get(`/mkt_broadcasting`)
export const postMktBroadcasting = (body) => API.post(`/mkt_broadcasting`, body)
export const deleteMktBroadcasting = (body) => API.delete(`/mkt_broadcasting`, body)
export const putMktBroadcasting = (body) => API.put(`/mkt_broadcasting`, body)
export const getTablesColumn = (table, column) => API.get(`/admin/tables?q={get(table:${table}) {${column}}}`)
// ads simulator
export const getAds = (timestamp, table) => API.get(`/v2/ads/${table}?timestamp=${timestamp}&forceUpdate=true`)
// upload image
export const postImage = (id, formData) => API.post(`/users/${id}/image/ads`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
// bot carousel
export const getAdvancedCondition = () => API.get('/ads/bot_carousel/advanced_condition')
export const getBotCarousel = (params) => API.get('/ads/bot_carousel', { params: { ...params } })

export const getModifyTable2 = ({ getTables = false, table, conditions = [] } = {}) => {
  const query = getTables ? ['tables(name:1)'] : []

  if (table) {
    query.push(`get(table: "${table}", ${conditions.map(({ key, value }) => `${key}: "${value}"`)})`)
  }

  return API.get(`/admin/tables?q={${query.join(',')}}`)
}

export const updateColumns = (table, id, body) => API.put(`/admin/tables/${table}/${id}`, body)
export const batchUpdateColumns = (table, body) => API.put(`/admin/tables/${table}/${body.id}/batch`, body)

// driver
export const getDriverInfo = (driver, params) => API.get(`v2/driver/info/${driver}`, { params })

// News
export const getNews = (type, params, id) => API.get(`v2/driver/news?type=${type}&driver_id=${id}`, { params })
export const createNews = (body) => API.post('v2/driver/news', body)
export const updateNews = (newsId, body) => API.put(`v2/driver/news/${newsId}`, body)
export const deleteNews = (newsId) => API.delete(`v2/driver/news/${newsId}`)
export const deleteInbox = (driverId, newsId) => API.delete(`v2/driver/${driverId}/news/${newsId}`)
export const createNotification = (body) => API.post('v2/drivers/notification', body)
export const deleteNotification = (notificationId) => API.delete(`v2/driver/batch_notification/${notificationId}`)

// Driver tag
export const getDriverTag = (uid) => API.get(`/admin?q={get_tag(uid: ${uid}, offset: 0, limit : 50)}`)
export const postTag = (body) => API.post('/tag', body)
export const deleteTag = (body) => API.delete('/tag', body)
export const searchTagName = (tagName) => API.get(`/admin?q={ tags(name:"${tagName}") }`)

// Coupons
export const getCoupons = (params) => API.get('v2/coupon', { params })
export const createCoupon = (body) => API.post('v2/coupon', body)
export const updateCoupon = (body) => API.put('v2/coupon', body)
export const deleteCoupon = (body) => API.delete('v2/coupon', body)
export const deleteBatch = (body) => API.delete('v2/batch_coupon', body)
export const getSerialCode = (batch, params) =>
  API.get(`v2/coupon/${batch}/download`, { headers: { 'Content-Type': 'application/octet-stream' }, responseType: 'text', params })
export const getCouponTypes = (params) => API.get('v2/coupon/type', { params })
export const getCouponCategories = (params) => API.get('v2/coupon/category', { params })

// Rental Coupons
export const getRentalCoupons = (params) => API.get('/v3/coupon/rental_coupons', { params })
export const getRentalCoupon = (id) => API.get(`/v3/coupon/rental_coupons/${id}`)
export const createRentalCoupon = (body) => API.post('/v3/coupon/rental_coupons', body)
export const updateRentalCoupon = (id, body) => API.put(`/v3/coupon/rental_coupons/${id}`, body)
export const deleteRentalCoupon = (id) => API.delete(`/v3/coupon/rental_coupons/${id}`)
export const deleteRentalBatch = (batch) => API.delete(`/v3/coupon/rental_coupons/batch/${batch}`)
export const getRentalCouponTypes = () => API.get('/v3/coupon/rental_type')
export const getRentalCouponCategories = () => API.get('/v3/coupon/rental_category')
export const getRentalCarSeries = () => API.get('/v3/coupon/rental_car_series')
export const getSrentalStations = () => API.get('/v3/coupon/srental_stations')
export const issueRentalCoupon = ({ promo_id, uid }) => API.post(`/v3/coupon/rental_coupons/${promo_id}`, { uid })

// event
export const getEvent = (params) => API.get('v2/mktScheduledEvent', { params })
export const postEventWithPin = (body) => API.post('v2/mktScheduledEvent', body)
export const deleteEvent = (body) => API.delete('v2/mktScheduledEvent', body)

// finance
export const postDriverList = (formData) =>
  API.post('/backend/driver_report', formData, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'text',
  })
export const getFinancePayment = (driver_id) => API.get(`/finance/platform_payment?driver_id=${driver_id}`)
export const addFinancePayment = (driver_id, body) => API.post(`/finance/platform_payment?driver_id=${driver_id}`, body)
export const deleteFinancePayment = (id) => API.delete(`/finance/platform_payment/${id}`)
export const postCtbcRecord = (formData) =>
  API.post('/backend/ctbc_record', formData, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'text',
  })
export const postDriverPayment = (formData) =>
  API.post('/backend/driver_payment', formData, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'text',
  })
export const postTransactionRecord = (fileType, formData) =>
  API.post(`/v3/report/${fileType}`, formData, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'text',
  })

export const postBatchAddDriverMileages = (formData) =>
  API.post(`/console/driver/mileage/batch`, formData, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'text',
  })

export const getDriverInsurance = (driver_id) => API.get(`/driver/insurance?driver_id=${driver_id}`)
export const postDriverInsuranceReceive = (body) => API.post('/driver/insurance/receive', body)
export const putDriverInsuranceRefund = (body) => API.put('/driver/insurance/refund', body)
export const getDriverSystemFeePayments = (limit, offset, driver_id) =>
  API.get(`/driver/system_fee/payments?limit=${limit}&offset=${offset}&driver_id=${driver_id}`)
export const postDriverSystemFeePayments = (formData) =>
  API.post(`/driver/system_fee/payments`, formData, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'text',
  })
export const putDriverSystemFeeRefund = (body) => API.put(`/driver/system_fee/refund`, body)
export const postUserReport = (formData) =>
  API.post('/backend/user_report', formData, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'text',
  })

// Happy hours
export const getHappyHours = () => API.get('v2/happy_hours')
export const deleteHappyHours = (body) => API.delete('v2/happy_hours', body)
export const createHappyHours = (body) => API.post('v2/happy_hours', body)
export const createHappyHoursAudience = (id) => API.post(`v3/audience/happy_hours/${id}`)
export const deleteHappyHoursAudience = (id) => API.delete(`v3/audience/happy_hours/${id}`)

// Rewards
export const getRewards = (page, limit) => API.get(`v2/drivers_rewards?page=${page}&limit=${limit}`)
export const createReward = (body) => API.post('v2/drivers_rewards', body)
export const deleteReward = (body) => API.delete('v2/drivers_rewards', body)

// Dispatch Area
export const getDispatchArea = (limit, offset) => API.get(`admin/tables?q={get(table:"trips_dispatch_area",limit:${limit},offset:${offset})}`)
export const postDispatchArea = (body) => API.post('admin/tables/trips_dispatch_area', body)
export const updateDispatchArea = (id, body) => API.put(`admin/tables/trips_dispatch_area/${id}`, body)

// company
export const getCompany = (limit, offset, query_string) =>
  API.get(`/company?q={company(offset:${offset}, limit:${limit} ,query_string:"${query_string}")}`)
export const createCompany = (body) => API.post('/company', body)
export const getCompanyById = (id) => API.get(`/company?q={company(id:${id})}`)
export const putCompany = (id, body) => API.put(`/company/${id}`, body)

export const getAgreement = (params) => API.get(`/company/agreement`, { params })
export const blockAgreement = (body) => API.delete('/company/agreement', { data: body })
export const blockPricePlan = (body) => API.delete(`/company/price_plan`, { data: body })
export const postCreateAgreement = (body) => API.post('/company/agreement', body)
export const postCreatePricePlan = (body) => API.post('/company/price_plan', body)
export const createCompanyUser = (id, body) => API.post(`/company/users/${id}`, body)

export const putCompanyUser = (unit_id, body) => API.put(`/company/users_unit/${unit_id}`, body)
export const forceCompanyUser = (unit_id, body) => API.put(`/company/users_unit_force/${unit_id}`, body)
export const setAdminPermission = (unit_id, body) => API.post(`/company/user_permission/${unit_id}`, body)
export const getCompanyUserList = (id) => API.get(`/company?q={users(companyId:${id})}`)
export const sendVerifyMail = (body) => API.post('/company/users/mail_verify', body)

export const getPaymentRecords = (params) => API.get(`/company/payment_records`, { params })
export const updatePaymentRecords = (body) => API.post('/company/payment_records', body)
export const refreshPayments = () => API.get(`/company/refresh_payments`)
export const openContent = (params) => API.get(`/company/bill_mail`, { params })
export const sendEmail = (body) => API.post('/company/bill_mail', body)
export const sendInvoiceEmail = (body) => API.post('/receipt/company/invoice_notification_email', body)

// User
export const getUserData = (user_id) => API.get(`/backend/user_data/${user_id}`)

// Transactions related
export const getTransactions = (params) => API.get(`/admin/payment`, { params })
export const processPayment = (body) => API.post(`/admin/payment`, body)
export const refundPayment = (body) => API.post(`/admin/payment/refund`, body)

// Veg Box
export const getVegBoxDriver = (driver_id) => API.get(`/veg_box/driver/${driver_id}`)
export const putVegBoxDriverSwitch = (driver_id) => API.put(`/veg_box/driver/${driver_id}/switch`)
export const postVegBoxDriverInventory = (driver_id, body) => API.post(`/veg_box/driver/${driver_id}/inventory`, body)
export const getVegBoxItems = () => API.get(`/veg_box/items`)

// Delivery
export const refundDeliveryOrders = (body) => API.post(`/delivery/order/refund`, body)
export const getDeliveryOrders = (date) => API.get(`/delivery/orders?date=${date}&limit=500`)
export const postDeliveryTrip = (body) => API.post(`/rides`, body)

// scheduler
export const getSchedulerList = () => API.get('/bee_on_time')
export const getSchedulerOptions = () => API.get('/bee_on_time/fields_options')
export const updateScheduler = (body) => API.post('/bee_on_time', body)
export const deleteScheduler = (body) => API.delete('/bee_on_time', { data: body })

// equipped
export const getEquippedList = (type) => API.get(`/equipped/query?review_type=${type}`)
export const postEquippedReview = (body) => API.post('/equipped', body)
export const postEquippedImage = (uid, body) => API.post(`users/${uid}/image/equipped`, body)
export const deleteEquipped = (body) => API.delete('/equipped', { data: body })
export const getReasonSuggestion = (params) => API.get('/cs/suggestion', { params })

// trip_transaction
export const getAdjustmentList = () => API.get('/fare/adjustment/pending_review')
export const getTripInfo = (trip_id, params) => API.get(`/trip/info/${trip_id}`, { params })
export const getRecalculatedFare = (trip_id) => API.get(`/v3/trip/fare/${trip_id}/recalculate`)
export const getTripAdjustmentReviews = (trip_id) => API.get(`/fare/adjustment/pending_review/${trip_id}`)
export const calculateFareAdjustment = (trip_id, body) => API.post(`/fare/adjustment/${trip_id}`, body)
export const addTripAdjustment = (trip_id, body) => API.post(`fare/adjustment/pending_review/${trip_id}`, body)
export const cancelTripAdjustment = (id) => API.put(`fare/adjustment/pending_review/cancel/${id}`)
export const reviewTripAdjustment = (id, body) => API.put(`/fare/adjustment/review/${id}`, body)
export const getReservationTrips = (driver_id) => API.get(`/reservations?driver_id=${driver_id}`)
export const releaseReservationTrip = (trip_id, body) => API.put(`/trips/${trip_id}/release`, body)
export const getUserCompanyInfo = (params) => API.get('/company_trips', { params })

// dama
export const airflowRuns = (body) => API.post('/dama/runs', body)
export const getUserGroup = (params) => API.get('/dama/userGroup', { params })
export const getUserGroupV2 = (params) => API.get('/dama/userGroup/v2', { params })
export const postUserGroup = (body) => API.post('/dama/userGroup', body)
export const toggleUserGroupAutoUpdate = (id, body) => API.put(`/dama/userGroup/auto_update/${id}`, body)
export const deleteUserGroup = (id) => API.delete(`/dama/userGroup/${id}`)
export const estimateUserGroup = (body) => API.post('dama/userGroup/estimate', body)
export const getUserGroupFilterOptions = (params) => API.get('/dama/userGroup/filter_options', { params })
export const uploadUserGroup = (formBody) =>
  API.post('/dama/userGroup/upload', formBody, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
export const getCampaigns = (params) => API.get('/dama/campaign', { params })
export const getCampaignsV2 = (params) => API.get('/dama/campaign/v2', { params })
export const getCampaign = (id) => API.get(`/dama/campaign/${id}`)
export const getTemplateCampaign = (id) => API.get(`/dama/campaign/template/${id}`)
export const postCampaign = (body) => API.post('/dama/campaign', body)
export const getCampaignSchedule = () => API.get('/dama/campaign/auto_schedule/launch')
export const deleteCampaign = (id) => API.delete(`/dama/campaign/${id}`)
// not-template
export const putUpdateCampaign = (id, body) => API.put(`/dama/campaign/${id}`, body)
export const putSubmitCampaign = (id, body) => API.put(`/dama/campaign/${id}/submit`, body)
// template
export const putUpdateTemplateCampaign = (id, body) => API.put(`/dama/campaign/template/${id}`, body)
export const putCampaignStatus = (id) => API.put(`/dama/campaign/template/schedule/${id}`)
export const disableTemplateCampaign = (id) => API.put(`/dama/campaign/template/${id}/disable`)

export const postDamaPushMessage = (body) => API.post('/dama/push', body)
export const postDamaImage = (type, body) => API.post(`/admin/image/${type}`, body)

// driver campaign
export const createEvent = (body) => API.post('/v2/admin/drivers/events/events', body)
export const updateEvent = (eventId, body) => API.put(`/v2/admin/drivers/events/events/${eventId}`, body)
export const removeEvent = (eventId, params) => API.delete(`/v2/admin/drivers/events/events/${eventId}`, { params })
export const createContent = (body) => API.post('/admin/drivers/events/contents', body)
export const createContents = (eventId, body) => API.post(`/admin/drivers/events/events/${eventId}/contents`, body)
export const updateContent = (id, body) => API.put(`/admin/drivers/events/contents/${id}`, body)
export const getDriversEvents = (params) => API.get('/admin/drivers/events', { params })
export const buildData = (contentID) => API.post(`/admin/drivers/events/contents/${contentID}/data`, {})
export const buildUsers = (eventID) => API.post(`/admin/drivers/events/events/${eventID}/users`, {})

// hr consoles
export const IdentityServices = {
  getIdentityRoles: () => API.get('/identity/admins/roles'),
  getIdentityAdmins: (params) => API.get('/identity/admins', { params }),
  postIdentityAdmins: (body) => API.post('/identity/admins', body),
  putIdentityAdmins: (id, body) => API.put(`/identity/admins/${id}`, body),
  postIdentityResetPassword: (id) => API.post(`/identity/admins/${id}/reset_password`),
  getPermissions: () => API.get('/identity/permissions'),
}

export const AuthServices = {
  postResetPassword: (body) => API.post('/identity/auth/admins/reset_password', body),
  postSignOut: () => API.post('/identity/auth/admins/signout'),
}

// driver-mileage
// 待 API 進到 RC 回來移除掉 Base URL
export const getDriverReward = (id) => API.get(`/console/driver/${id}/mileage`)
export const postDriverReward = (id, body) => API.post(`/console/driver/${id}/mileage`, body)
export const getExchangeStuff = (id) => API.get(`/console/driver/${id}/mileage/items`)
export const postExchangeStuff = (id, body) => API.post(`/console/driver/${id}/mileage/items`, body)
export const getDriverNameById = (id) => API.get(`/console/driver/${id}/info`)
export const getDriverRewardList = (id) => API.get(`/console/driver/${id}/mileage/history`)
export const deleteDriverReward = (did, id) => API.delete(`console/driver/${did}/mileage/${id}`)

// driver identifiers
export const getDriversIdentifiers = (did) => API.get(`/driver/identifiers/${did}/logs`)
export const postDriversIdentifiers = (did, body) => API.post(`/driver/identifiers/${did}/logs`, body)
export const getDriversIdentifiersInfo = (did) => API.get(`/driver/identifiers/${did}`)
export const getExchangeStores = () => API.get(`/driver/exchange_store`)
export const getIdentifiersItems = () => API.get(`/driver/identifiers`)

// blocked history
export const blockUser = (body) => API.post('/driver/blockUser', body)
export const unblockUser = (body) => API.post('/driver/unblockUser', body)

export const blockDriversBatch = (formData) =>
  API.post(`console/driver/block/batch`, formData, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'text',
  })

// canceled reservation
export const canceledReservation = (did) => API.get(`/driver/canceled_reservation/${did}`)
export const putCanceledReservation = (id, body) => API.put(`/driver/canceled_reservation/${id}`, body)
export const postCanceledReservation = (body) => API.post('/driver/canceled_reservation', body)
export const toggleReservation = (body) => API.post('/driver/reservation/switch', body)

export const canceledReservationsBatch = (formData) =>
  API.post(`console/driver/canceled_reservations`, formData, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'text',
  })

// receipt
export const receiptLogs = (params) => API.get('/receipt/logs', { params })
export const putReceipt = (tripId, body) => API.put(`/receipt/${tripId}`, body)
export const sendReceipt = (body) => API.post('/rides/receipt', body)

// user info
export const searchUser = (params) => API.get('/users/findOne', { params })
export const getUserInfo = (uid, params) => API.get(`/rider/info/${uid}`, { params })
export const getUsersOrderDetail = (uid, orderHash, params) => API.get(`/v3/identity/users/${uid}/orders/${orderHash}`, { params })

export const getDriversViceContractLog = (did) => API.get(`/console/driver/${did}/vice_contract/history`)

// surge price multiplier tests
export const getSurgePriceMultiplierTests = (params) => API.get('/v3/trip/surge_price_multiplier_tests', { params })
export const getSurgePriceMultiplierTest = (id) => API.get(`/v3/trip/surge_price_multiplier_tests/${id}`)
export const getSurgePriceMultiplierLastModified = () => API.get('/v3/trip/surge_price_multiplier_tests/last_modified')
export const patchSurgePriceMultiplierTest = (id, body) => API.patch(`/v3/trip/surge_price_multiplier_tests/${id}`, body)
export const createSurgePriceMultiplierTests = (formData) =>
  API.post('/v3/trip/surge_price_multiplier_tests', formData, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'text',
  })
