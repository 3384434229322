import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Panel, Pagination, Alert, Button, IconButton, Icon } from 'rsuite'
import dayjs from 'dayjs'
import { Wrapper, Heading } from '../../components/views/styles'
import { TagItem } from '../../components/rental_coupons'
import { getRentalCoupons, deleteRentalCoupon, deleteRentalBatch, getSerialCode } from '../../api'
import { SearchInput } from '../../components/table'

const subService = {
  rental: '共享',
  srental: '門市',
  all: '共享＋門市',
}

const dateFormat = (v) => {
  if (!v) return '-'
  return dayjs(v).format('YYYY-MM-DD HH:mm')
}

const TABLE_COLUMNS = [
  { label: 'ID', dataKey: 'id', width: 80 },
  { label: '種類', dataKey: 'promo_type', width: 150 },
  { label: '名稱', dataKey: 'promo_title', width: 150 },
  { label: '開始時間', dataKey: 'start_date', width: 150, format: (v) => dateFormat(v * 1000) },
  { label: '結束時間', dataKey: 'end_date', width: 150, format: (v) => dateFormat(v * 1000) },
  { label: '每次領取', dataKey: 'count', width: 100 },
  { label: '適用服務', dataKey: 'sub_service', width: 100, format: (v) => subService[v] },
  { label: '總發張數', dataKey: 'total_number_of_coupons', width: 100, format: (v) => (v === -1 ? '無限制' : v) },
  { label: '折扣', dataKey: 'amount', width: 100 },
  { label: '操作', dataKey: 'action', width: 150, align: 'center' },
]

const RentalCoupons = () => {
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(1)
  const [coupons, setCoupons] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const limit = 20
  const history = useHistory()

  const fetch = async ({ limit, offset = 0 }) => {
    const params = {
      limit,
      offset,
      keyword,
    }
    setIsLoading(true)
    const { data, status } = await getRentalCoupons(params)
    setIsLoading(false)
    if (status !== 200) {
      Alert.error('rental coupons fetch failed')
      return
    }
    setCoupons(data)
  }

  useEffect(() => {
    fetch({ limit: limit + 1, offset: 0 })
  }, [])

  const handlePageChange = (value) => {
    if (value === page) return
    setPage(value)
    fetch({ limit: limit + 1, offset: limit * (value - 1) })
  }

  const search = async ({ limit, offset = 0 }) => {
    if (keyword.length > 0) {
      setPage(1)
    }
    await fetch({ limit, offset })
  }

  const handleDelete = async (promo_id, batch) => {
    let response
    if (batch) {
      response = await deleteRentalBatch(batch)
    } else {
      response = await deleteRentalCoupon(promo_id)
    }
    if (response.status !== 200) {
      Alert.error('操作失敗')
      return
    }
    Alert.info(`已刪除 promo_id 為 ${promo_id} 的租車優惠券`, 3000)
    fetch({ limit: limit + 1, offset: 0 })
    setPage(1)
  }

  const handleDownloadCode = async (batch) => {
    const response = await getSerialCode(batch, { type: 'rental' })
    if (response.status !== 200) {
      Alert.error('操作失敗')
      return
    }
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `rental-batch-${batch}`)
    document.body.appendChild(link)
    link.click()
  }

  return (
    <Wrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Heading>租車優惠券</Heading>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SearchInput
          title="搜索優惠券名稱或promo_id"
          value={keyword}
          setSearchWord={setKeyword}
          withButton
          onClick={() => search({ limit: limit + 1, offset: 0 })}
          onKeyDown={(e) => {
            if (e.keyCode === 13) search({ limit: limit + 1, offset: 0 })
          }}
        />
        <Button appearance="ghost" onClick={() => history.push('/marketing/rental_coupons/add')}>
          + 新增優惠券
        </Button>
      </div>
      <Panel bordered bodyFill style={{ paddingBottom: 12, backgroundColor: '#fff' }}>
        <Table style={{ minWidth: 400 }} autoHeight rowHeight={90} data={coupons.slice(0, limit)} loading={isLoading} wordWrap>
          {TABLE_COLUMNS.map((v) => (
            <Column key={v.dataKey} width={v.width} align={v.align} verticalAlign="middle">
              <HeaderCell>{v.label}</HeaderCell>
              <Cell>
                {(rowData) => {
                  if (v.dataKey === 'promo_type') {
                    if (rowData.batch) {
                      return (
                        <div>
                          <TagItem type={rowData.promo_type} />
                          <Button color="red" appearance="ghost" size="xs" onClick={() => handleDownloadCode(rowData.batch)} style={{ marginTop: 8 }}>
                            <Icon icon="copy" />
                            {' 批量序號'}
                          </Button>
                        </div>
                      )
                    }
                    return <TagItem type={rowData.promo_type} />
                  }
                  if (v.dataKey === 'amount') {
                    if (rowData.amount === 0 && rowData.discount_time > 0) {
                      return (
                        <span style={{ color: '#575757', wordBreak: 'break-word', ...(v.style && { ...v.style }) }}>
                          {rowData.discount_time}
                          {' 分鐘'}
                        </span>
                      )
                    }
                    return (
                      <span style={{ color: '#575757', wordBreak: 'break-word', ...(v.style && { ...v.style }) }}>
                        {rowData.amount > 0 && rowData.amount < 1 ? `${rowData.amount * 10} 折` : `${rowData.amount} 元`}
                      </span>
                    )
                  }
                  if (v.dataKey === 'action') {
                    return (
                      <>
                        {rowData.promo_type !== 'SERIAL_NUMBER' && (
                          <IconButton
                            onClick={() => history.push(`/marketing/rental_coupons/${rowData.id}`)}
                            appearance="subtle"
                            icon={<Icon icon="edit2" />}
                          />
                        )}
                        <IconButton
                          onClick={() => history.push(`/marketing/rental_coupons/${rowData.id}/copy`)}
                          appearance="subtle"
                          icon={<Icon icon="copy-o" />}
                          style={{ marginLeft: 6 }}
                        />
                        <IconButton
                          onClick={handleDelete.bind(null, rowData.id, rowData.batch)}
                          appearance="subtle"
                          icon={<Icon icon="trash2" />}
                          style={{ marginLeft: 6 }}
                        />
                      </>
                    )
                  }
                  return (
                    <span style={{ color: '#575757', wordBreak: 'break-word', ...(v.style && { ...v.style }) }}>
                      {v.format ? v.format(rowData[v.dataKey], rowData) : rowData[v.dataKey]}
                    </span>
                  )
                }}
              </Cell>
            </Column>
          ))}
        </Table>
        <Pagination
          prev
          next
          maxButtons={1}
          size="xs"
          activePage={page}
          onSelect={handlePageChange}
          pages={page + Math.floor(coupons.length / (limit + 1))}
          style={{ marginTop: 12, marginRight: 12, float: 'right' }}
        />
      </Panel>
    </Wrapper>
  )
}

export default RentalCoupons
