import React from 'react'
import { Modal, Button } from 'rsuite'
import dayjs from 'dayjs'
import TextRow from './TextRow'
import Subtitle from './Subtitle'

const dateFormat = (value) => (value ? dayjs(value).format('YYYY/MM/DD HH:mm') : '-')

const InfoModal = ({ show, close, info, detail }) => {
  const rentalInfo = info.info
  const { billing } = info
  return (
    <Modal backdrop show={show} onHide={close} className="order-info">
      <Modal.Header>
        <Modal.Title style={{ fontWeight: 'normal' }}>租車資訊</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {info.type !== 'srental' ? (
          <>
            <TextRow label="車牌號碼" value={info.plateNo} />
            <TextRow label="車輛型號" value={rentalInfo?.carInfo?.carSeries} />
            <TextRow label="租還方式" value={info.returnMethod} />
            <TextRow label="行駛里程" value={billing?.odoMeter} />
            <Subtitle>取車資訊</Subtitle>
            <TextRow label="實際取車時間" value={dateFormat(billing?.rentStart)} />
            <TextRow label="取車站點" value={rentalInfo?.parkingInfos?.name} />
            <TextRow label="取車里程" value={billing?.endOdoMeter - billing?.odoMeter} />
            <Subtitle>還車資訊</Subtitle>
            <TextRow label="實際還車時間" value={dateFormat(billing?.rentEnd)} />
            <TextRow label="還車站點" value={rentalInfo?.parkingInfos?.name} />
            <TextRow label="還車里程" value={billing?.endOdoMeter} />
          </>
        ) : (
          <>
            <Subtitle>車型資訊</Subtitle>
            <TextRow label="車輛型號" value={detail.carModelInfo?.name} />
            <Subtitle>取車資訊</Subtitle>
            <TextRow label="取車站點" value={detail.fromStation?.name} />
            <TextRow label="取車時間" value={dateFormat(detail.startTime)} />
            <Subtitle>還車資訊</Subtitle>
            <TextRow label="還車站點" value={detail.toStation?.name} />
            <TextRow label="還車時間" value={dateFormat(detail.endTime)} />
            <Subtitle>配件資訊</Subtitle>
            {detail.priceInfo?.accessory?.map((item) => (
              <TextRow key={item.code} label={item.name} value={item.qty} />
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={close}>
          關閉
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(InfoModal)
