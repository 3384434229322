import React from 'react'
import { Modal, Button } from 'rsuite'
import TextRow, { MemoizedTextRowThreeColumns as TextRowThreeColumns } from './TextRow'
import Subtitle from './Subtitle'
import TransactionTable from './TransactionTable'

const invoiceType = {
  2: '二聯發票',
  3: '三聯發票',
}

const invoiceCategory = {
  3: '手機條碼',
  5: '會員載具',
}

const DetailModal = ({ show, close, info, invoice, detail }) => {
  const { billing, supplementaries = [] } = info
  return (
    <Modal backdrop show={show} onHide={close} className="order-detail">
      <Modal.Header>
        <Modal.Title style={{ fontWeight: 'normal' }}>交易紀錄與明細</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextRow label="訂單ID" value={info.id} />
        <TextRow label="訂單編號" value={info.hash} />
        <Subtitle>租金總計</Subtitle>

        {info.type !== 'srental' ? (
          <>
            <TextRow label="時間租金" value={billing?.timeAmount} prefix="$" />
            <TextRow label="優惠方案" show={!!billing?.promotionInfos?.length} value={billing?.promotionDiscount} prefix="-$" />
            <TextRow label="優惠券" show={!!billing?.usedCoupons?.[0]?.discount} value={billing?.usedCoupons?.[0]?.discount} prefix="-$" />
          </>
        ) : (
          <>
            <TextRowThreeColumns label1="線上付款明細" label2="租金" prefix="$" value={detail?.amount} show={!!detail?.amount} />
            <TextRowThreeColumns label2="安免費用" prefix="$" value={detail?.priceInfo?.noDutyAmount} show={!!detail?.priceInfo?.noDutyAmount} />
            {/* 加項處理 */}
            {detail?.priceInfo?.surcharge?.map((item, _index) => (
              <TextRowThreeColumns label2={item.content} prefix="$" value={item.amount} />
            ))}
            {/* 還車時費用 */}
            {detail?.priceInfo?.returnCarCharge?.map((item, _index) => (
              <TextRowThreeColumns label2={item.content} prefix="$" value={item.amount} />
            ))}
            {/* 折扣項目處理 */}
            {detail?.priceInfo?.discount?.map((item, _index) => (
              <TextRowThreeColumns label2={item.content} prefix="-$" value={item.amount} />
            ))}
            <TextRowThreeColumns label1="門市付款明細" label2="配件費用" prefix="$" value={detail?.priceInfo?.accessoryAmount} />
          </>
        )}
        <Subtitle>額外費用</Subtitle>
        {info.type !== 'srental' ? (
          <>
            {supplementaries.map((s) => {
              return <TextRowThreeColumns label1={s.payForName} label2={s.paid ? '已付款' : '未付款'} value={s.amount} prefix="$" />
            })}
          </>
        ) : (
          <>
            <TextRowThreeColumns
              label1="車損"
              label2="實收車損"
              prefix="$"
              value={detail?.priceInfo?.accidentPriceInfo?.carLossAmt}
              show={!!detail?.priceInfo?.accidentPriceInfo}
            />
            <TextRowThreeColumns
              label1=""
              label2="實收營損"
              prefix="$"
              value={detail?.priceInfo?.accidentPriceInfo?.bizLossAmt}
              show={!!detail?.priceInfo?.accidentPriceInfo}
            />
            <TextRowThreeColumns label1="取消費" prefix="$" value={detail?.priceInfo?.cancelFee} show={!!detail?.priceInfo?.cancelFee} />
          </>
        )}
        <Subtitle>發票資訊</Subtitle>
        {info.type !== 'srental' ? (
          <>
            <TextRow label="發票類型" value={invoiceType[invoice?.type] || invoice?.type} />
            <TextRow label={invoiceCategory[invoice?.category] || '載具ID'} value={invoice?.carrierID} />
            <TextRow show={!!invoice?.id} label="統一編號" value={invoice?.id} />
            <TextRow show={!!invoice?.id} label="發票抬頭" value={invoice?.title} />
          </>
        ) : (
          <>
            {detail?.invoice?.map((item, _index) => (
              <React.Fragment key={item.invoiceNo}>
                <TextRowThreeColumns label1={`發票${_index + 1}`} label2="發票類型" value={invoiceType[item?.invoiceType] || item?.invoiceType} />
                <TextRowThreeColumns label2={invoiceCategory[item?.invoiceCategory] || '載具ID'} value={item?.invoiceCarrierId} />
                <TextRowThreeColumns label2="統一編號" value={item?.invoiceId} show={!!item?.invoiceId} />
                <TextRowThreeColumns label2="發票抬頭" value={item?.invoiceTitle} show={!!item?.invoiceTitle} />
              </React.Fragment>
            ))}
          </>
        )}
        <Subtitle>交易紀錄</Subtitle>
        <TransactionTable data={info.transactions} />
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={close}>
          關閉
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(DetailModal)
