import React from 'react'
import { DatePicker } from 'rsuite'

const DateTimePicker = React.memo(({ value, handleSelect }) => {
  return (
    <DatePicker
      format="YYYY-MM-DD HH:mm"
      onSelect={handleSelect}
      onOk={handleSelect}
      cleanable={false}
      value={value ? new Date(value * 1000) : null}
      ranges={[]}
      placeholder="選擇日期和時間"
      hideMinutes={(minute) => minute % 5 !== 0 && minute !== 59}
      style={{ width: 320 }}
    />
  )
})

export default DateTimePicker
