import React from 'react'
import { SelectPicker } from 'rsuite'
import { subservice } from '../../pages/rental_coupons/subservice'

const RentalSubservicePicker = React.memo(({ value, onChange, disabled = false }) => {
  const data = subservice.map((v) => ({ ...v, label: v.name }))

  return (
    <SelectPicker disabled={disabled} onChange={onChange} searchable={false} cleanable={false} data={data} value={value} style={{ width: 220 }} />
  )
})

export default RentalSubservicePicker
