/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Alert, Loader, Message } from 'rsuite'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import { find, isEmpty } from 'lodash'
import usePageSetting from '../../hook/usePageSetting'
import { getAdjustmentList, getTripInfo, getTripAdjustmentReviews, addTripAdjustment, getUserCompanyInfo } from '../../api'
import {
  TripInfo,
  TripTransactionModal,
  ConfirmModal,
  ReviewModal,
  CompanyTripModal,
  ConfirmCompanyTripModal,
} from '../../components/trip_transaction'
import { DateCell, WhisperCell, ButtonCell } from '../../components/table/cell'
import { ListWrap, AddListModel, AddListGrop, BreadcrumbNav } from '../../components/views'
import PopWarning from '../../components/popWarning'
import { TableController, SearchInput } from '../../components/table'
import 'react-datepicker/dist/react-datepicker.css'

dayjs.extend(isBetween)
const Container = styled.div`
  width: 100%;
  .rs-loader-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100;
  }
`
const initCurrentInfo = {
  trip_id: null,
  driver_id: 0,
  previous_amount: 0, // 總車資
  previous_service_fee: 0, // 司機平台使用費
  previous_grading_cashback: 0, // 行程回饋金
  previous_coupon: {}, // 優惠券
  previous_discount: 0, // 優惠券折扣
  previous_fare: 0, // 乘客實際需付
  previous_miles: 2, // T里程
  previous_linego_points: [], // Linego點數
  previous_cash: 0, // 乘客已付現金
  previous_credit_card: 0, // 乘客已付線上刷卡
  previous_company: 0, // 乘客已付企業簽單
  new_amount: 0,
  new_service_fee: 0,
  new_grading_cashback: 0,
  new_coupon: null,
  new_discount: 0,
  new_fare: 0,
  new_miles: 0,
  new_linego_points: [],
  new_cash: 0,
  new_credit_card: 0,
  new_company: 0,
  offlineTransaction: {},
  refund: 0,
  collectable: 0,
  reason: '',
  calculation: null, // 送出審核 => 需要帶整包計算結果
}

const TripTransaction = () => {
  const [loading, setLoading] = useState(false)
  const [showConfirmation, setConfirmation] = useState(false)
  const [showApplication, setApplication] = useState(false)
  const [popupCompanyTrip, setPopupCompanyTrip] = useState(false)
  const [popupConfirmCompanyTrip, setPopupConfirmCompanyTrip] = useState(false)
  const [showReview, setReview] = useState(false)
  const [searchWord, setSearchWord] = useState('')
  const [tripData, setTripData] = useState({ tripInfo: {}, adjustment: [], companyInfo: [] })
  const [companies, setCompanies] = useState([])
  const [companyTripInfo, setCompanyTripInfo] = useState({})
  const [companyTripPayload, setCompanyTripPayload] = useState(null)
  const [companyInfo, setCompanyInfo] = useState({})
  const { isAddMode, list, currentInfo, warning, toggleAddMode, handleOnChange, setList, setCurrentInfo, setWarning } =
    usePageSetting(initCurrentInfo)

  const fetchAdjustmentList = async () => {
    const response = await getAdjustmentList()
    if (response.status !== 200) return Alert.error(response.data.msg)
    const { data } = response
    return setList(data)
  }

  const fetchTripData = async (trip_id) => {
    setLoading(true)
    const infoResponse = await getTripInfo(trip_id, {
      q: '{ basic, coupons, driver_service_fee, t_membership_mileage, linego_points, transaction_summary, cancel_fee_summary, driver_grading_cashback }', // LT-8842 拿取消費紀錄 cancel_fee_summary
    })
    const adjustmentResponse = await getTripAdjustmentReviews(trip_id)
    setLoading(false)
    if (infoResponse.status !== 200 || adjustmentResponse.status !== 200) return setWarning(infoResponse.data.msg)
    const { data } = infoResponse.data
    const records = adjustmentResponse.data
    const companyInfoResponse = await getUserCompanyInfo({ q: `{avaiableForUsing(uid:${data?.basic?.user_id})}` })
    if (companyInfoResponse.status !== 200) return setWarning(companyInfoResponse.data.msg)
    const companyInfo = companyInfoResponse.data.data.avaiableForUsing.map((v) => ({
      id: v.id,
      name: v.name,
      isBlocked: v.isBlocked,
      groups: v.groups,
    }))
    window.history.replaceState(null, null, `${window.location.pathname}?trip=${trip_id}`)
    return setTripData({ tripInfo: data, adjustment: records, companyInfo })
  }

  const handleSearch = () => {
    if (!searchWord) return
    fetchTripData(searchWord)
    setSearchWord('')
  }

  const handleAdjustmentFormData = () => {
    const { tripInfo, adjustment } = tripData
    if (find(adjustment, { status: 'PENDING' })) return Alert.warning('目前有正在申請中的變更，請先撤銷再重新申請')
    const {
      basic,
      coupons,
      driver_service_fee,
      t_membership_mileage,
      linego_points,
      transaction_summary,
      cancel_fee_summary,
      driver_grading_cashback,
    } = tripInfo
    const { trip_id, amount, trip_status, payment_method, reserve_time } = basic
    const coupon = coupons[coupons.length - 1] || { coupon_id: null, type: 'NO_COUPONS', redeem_value: 0 }
    const { total_cash, total_online, total_company } = transaction_summary

    // 帶入目前行程的金流資料到 currentInfo
    setCurrentInfo({
      ...currentInfo,
      trip_id,
      reserveTime: reserve_time,
      previous_trip_status: trip_status,
      previous_payment_method: payment_method,
      previous_amount: amount,
      previous_service_fee: isEmpty(driver_service_fee) ? 0 : driver_service_fee[driver_service_fee.length - 1].fee,
      previous_grading_cashback: driver_grading_cashback, // LT-12434 修改車資自動化-回饋金
      previous_coupon: coupon,
      previous_discount: coupon.redeem_value,
      previous_fare: amount - coupon.redeem_value,
      previous_miles: isEmpty(t_membership_mileage) ? 0 : t_membership_mileage[t_membership_mileage.length - 1].miles,
      previous_linego_points: linego_points,
      previous_cash: total_cash,
      previous_credit_card: total_online,
      previous_company: total_company,
      previous_cancel_fee: cancel_fee_summary?.total || 0,
    })
    return setApplication(true)
  }

  const checkTripAdjustment = ({ trip_id }) => {
    if (!trip_id) return
    fetchTripData(trip_id)
  }

  const handleAddAdjustment = async () => {
    const { trip_id, calculation, reason } = currentInfo
    if (!calculation || !reason) return Alert.error('請先計算好新車資')
    const body = {
      calculation: JSON.stringify(calculation),
      submit_note: reason,
    }

    const response = await addTripAdjustment(trip_id, body)
    if (response.status !== 200) return Alert.error(response.data.msg)
    setCurrentInfo(initCurrentInfo)
    setConfirmation(false)
    setApplication(false)
    fetchTripData(trip_id)
    return setWarning('已送出變更車資的申請')
  }

  const handleWarningConfirm = () => {
    setWarning('')
    setList([])
    fetchAdjustmentList()
  }

  const handleAdjustmentForCompany = () => {
    const { adjustment, companyInfo, tripInfo } = tripData
    if (find(adjustment, { status: 'PENDING' })) return Alert.warning('目前有正在申請中的變更，請先撤銷再重新申請')

    setCompanies(
      companyInfo.map((v) => ({ label: v.name, value: v.id, groups: v.groups.map((group) => ({ label: group.group_name, value: group.group_id })) })),
    )
    const {
      basic: { trip_id, amount, trip_status, payment_method },
      coupons = [],
    } = tripInfo
    setCompanyTripInfo({
      trip_id,
      cash: 0,
      coupon_id: coupons[coupons.length - 1]?.coupon_id || 0,
      fare: amount,
      trip_status,
      payment_method,
    })
    setPopupCompanyTrip(true)
  }

  const popupCompanyTripConfirm = (payload) => {
    setCompanyTripPayload(payload)
    const company = companies.find((v) => v.value === payload.calculation?.after?.company_payment?.company)
    setCompanyInfo({
      company: company.label,
      group: company.groups.find((v) => v.value === payload.calculation?.after?.company_payment?.group)?.label,
    })
    setPopupConfirmCompanyTrip(true)
  }

  const submitCompanyTrip = async () => {
    const response = await addTripAdjustment(companyTripInfo.trip_id, {
      calculation: JSON.stringify(companyTripPayload.calculation),
      submit_note: companyTripPayload.submit_note,
    })
    if (response.status !== 200) return Alert.error(response.data.msg)
    fetchTripData(companyTripInfo.trip_id)
    setPopupConfirmCompanyTrip(false)
    setPopupCompanyTrip(false)
    setWarning('已送出變更為企業簽單的申請')
  }

  const memoedIsShowCashback = useMemo(() => {
    if (!currentInfo?.reserveTime) return false
    const month = dayjs().format('MM')
    const tripMonth = dayjs(currentInfo?.reserveTime * 1000).format('MM')
    return month !== tripMonth
  }, [currentInfo?.reserveTime])

  const memoedIsDuringProhibited = useMemo(() => {
    const startTime = dayjs(new Date(dayjs().year(), dayjs().month(), 1, 1, 0, 0, 0))
    const endTime = dayjs(new Date(dayjs().year(), dayjs().month(), 1, 4, 0, 0, 0))
    return dayjs().isBetween(startTime, endTime)
  }, [])
  useEffect(() => {
    const toggleSpecificTrip = () => {
      const params = new URLSearchParams(window.location.search)
      const tripId = params.get('trip')
      if (tripId) {
        fetchTripData(tripId)
      }
    }

    toggleSpecificTrip()
    fetchAdjustmentList()
  }, [])

  useEffect(() => {
    if (isEmpty(tripData.tripInfo)) return
    console.log('fetch tripData===============', tripData)
    if (!isAddMode) toggleAddMode()
  }, [tripData])

  return (
    <>
      <Container>
        {loading && <Loader content="loading" size="md" />}
        {warning && <PopWarning warning={warning} textCenter confirm={handleWarningConfirm} />}
        {/* 審核車資 */}
        <ReviewModal
          tripDate={tripData}
          show={showReview}
          handleClose={() => setReview(false)}
          fetchTripData={fetchTripData}
          fetchList={fetchAdjustmentList}
          loading={loading}
          setLoading={setLoading}
        />
        {/* 確認計算後的金流 */}
        {showConfirmation && (
          <ConfirmModal
            currentInfo={currentInfo}
            show={showConfirmation}
            handleClose={() => setConfirmation(false)}
            handleAddAdjustment={handleAddAdjustment}
            isShowCashback={memoedIsShowCashback}
          />
        )}
        {/* 跳出行程金流的變更表格 */}
        <TripTransactionModal
          currentInfo={currentInfo}
          show={showApplication}
          handleClose={() => {
            setCurrentInfo(initCurrentInfo)
            setApplication(false)
          }}
          handleOnChange={handleOnChange}
          setCurrentInfo={setCurrentInfo}
          setConfirmation={setConfirmation}
          isShowCashback={memoedIsShowCashback}
          isDuringProhibted={memoedIsDuringProhibited}
        />
        <CompanyTripModal
          companies={companies}
          tripInfo={companyTripInfo}
          show={popupCompanyTrip}
          close={setPopupCompanyTrip.bind(null, false)}
          confirm={popupCompanyTripConfirm}
        />
        <ConfirmCompanyTripModal
          show={popupConfirmCompanyTrip}
          close={setPopupConfirmCompanyTrip.bind(null, false)}
          confirm={submitCompanyTrip}
          payload={companyTripPayload}
          companyInfo={companyInfo}
        />
        {isAddMode ? (
          <AddListModel>
            <BreadcrumbNav
              start="審核車資"
              entry="申請修改金流"
              onClick={() => {
                toggleAddMode()
                window.history.replaceState(null, null, window.location.pathname)
              }}
            />
            <AddListGrop title="行程金流">
              {memoedIsDuringProhibited && <Message type="error" description="為避免車資異動與結算異常，目前時段禁止操作車資異動" />}
              <TripInfo
                tripData={tripData}
                handleApply={handleAdjustmentFormData}
                handleApplyCompany={handleAdjustmentForCompany}
                fetchList={fetchAdjustmentList}
                fetchTripData={fetchTripData}
                setReview={setReview}
                handleAddAdjustment={handleAddAdjustment}
              />
            </AddListGrop>
          </AddListModel>
        ) : (
          <>
            <ListWrap status="審核車資" />
            <SearchInput
              title="搜尋行程 TID"
              setSearchWord={setSearchWord}
              onClick={handleSearch}
              onKeyDown={(e) => {
                if (e.keyCode === 13) handleSearch()
              }}
            />
            <TableController data={list} topHeight={104} rowHeight={60}>
              <Column flexGrow={0.5} align="center">
                <HeaderCell>申請單</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column flexGrow={0.5} align="center">
                <HeaderCell>行程 TID</HeaderCell>
                <Cell dataKey="trip_id" />
              </Column>
              <Column flexGrow={0.5} align="center">
                <HeaderCell>司機 DID</HeaderCell>
                <Cell dataKey="driver_id" />
              </Column>
              <Column flexGrow={0.5} align="center">
                <HeaderCell>乘客 UID</HeaderCell>
                <Cell dataKey="user_id" />
              </Column>
              <Column flexGrow={0.5} align="center">
                <HeaderCell>更新時間</HeaderCell>
                <DateCell dataKey="last_update" format="YYYY-MM-DD HH:mm:ss" />
              </Column>
              <Column flexGrow={2} align="center">
                <HeaderCell>車資異動原因</HeaderCell>
                <WhisperCell dataKey="submit_note" />
              </Column>
              <Column flexGrow={1.5} align="center">
                <HeaderCell>審核申請</HeaderCell>
                <ButtonCell title="審核申請" color="yellow" handleClick={checkTripAdjustment} />
              </Column>
            </TableController>
          </>
        )}
      </Container>
    </>
  )
}

export default TripTransaction
