import React from 'react'
import { TextWrapper, TextWrapper2 } from './styles'

const TextRow = ({ label, value, prefix = '', show = true }) => {
  return show ? (
    <TextWrapper>
      <div>{label}</div>
      <div>{value !== undefined && value !== null && !Number.isNaN(value) ? `${prefix}${value}` : '-'}</div>
    </TextWrapper>
  ) : (
    <></>
  )
}

const TextRowThreeColumns = ({ label1 = '', label2 = '', value, show = true }) => {
  return show ? (
    <TextWrapper2>
      <div>{label1}</div>
      <div>{label2 !== undefined && label2 !== null && !Number.isNaN(label2) ? `${label2}` : '-'}</div>
      <div>{value !== undefined && value !== null && !Number.isNaN(value) ? `${value}` : '-'}</div>
    </TextWrapper2>
  ) : (
    <></>
  )
}

export default React.memo(TextRow)
export const MemoizedTextRowThreeColumns = React.memo(TextRowThreeColumns)
