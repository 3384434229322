import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Cell, Column, HeaderCell, Table } from 'rsuite-table'
import { Panel, Pagination, Button } from 'rsuite'
import OrderStatus from './OrderStatus'

const TABLE_COLUMNS = [
  { label: '建立時間', dataKey: 'created_at', width: 150, format: (v) => dateFormat(v) },
  { label: '訂單ID', dataKey: 'id', width: 80 },
  { label: '訂單編號', dataKey: 'hash', width: 100 },
  { label: '訂單金額', dataKey: 'amount', width: 100, format: (v, row) => amountFormat(v, row) },
  { label: '交易紀錄', dataKey: 'transactions', width: 150 },
  { label: '預約取車時間', dataKey: 'reservationStart', width: 150, format: (v) => dateFormat(v) },
  { label: '預約還車時間', dataKey: 'reservationEnd', width: 150, format: (v) => dateFormat(v) },
  { label: '租車資訊', dataKey: 'info', width: 100 },
  { label: '訂單狀態', dataKey: 'status', width: 200 },
]

const dateFormat = (v) => {
  if (!v) return '-'
  return dayjs(v).format('YYYY-MM-DD HH:mm')
}

const amountFormat = (v, row) => {
  const amount = row?.billing?.payAmount ?? v
  if (!amount) return '-'
  return `$${amount}`
}

const OrderTable = React.memo(({ title, data, isLoading, popupDetail, popupInfo, fetch }) => {
  const [page, setPage] = useState(1)
  const limit = 5

  const handlePageChange = (value) => {
    if (value === page) return
    setPage(value)
    fetch({ limit: limit + 1, offset: limit * (value - 1) })
  }

  return (
    <Panel header={title} bordered bodyFill style={{ paddingBottom: 12 }}>
      <Table style={{ minWidth: 400, height: 270 }} autoHeight data={data.slice(0, limit)} loading={isLoading} wordWrap>
        {TABLE_COLUMNS.map((v) => (
          <Column key={v.dataKey} width={v.width} align={v.align} verticalAlign="middle">
            <HeaderCell>{v.label}</HeaderCell>
            <Cell>
              {(rowData) => {
                if (v.dataKey === 'transactions') {
                  return (
                    <Button onClick={() => popupDetail(rowData)} appearance="link" size="sm">
                      展開交易紀錄
                    </Button>
                  )
                }
                if (v.dataKey === 'info') {
                  return (
                    <Button onClick={() => popupInfo(rowData)} appearance="link" size="sm">
                      展開資訊
                    </Button>
                  )
                }
                if (v.dataKey === 'status') {
                  return <OrderStatus status={rowData[v.dataKey]} />
                }
                return (
                  <span style={{ color: '#575757', wordBreak: 'break-word', ...(v.style && { ...v.style }) }}>
                    {v.format ? v.format(rowData[v.dataKey], rowData) : rowData[v.dataKey]}
                  </span>
                )
              }}
            </Cell>
          </Column>
        ))}
      </Table>
      <Pagination
        prev
        next
        maxButtons={1}
        size="xs"
        activePage={page}
        onSelect={handlePageChange}
        pages={page + Math.floor(data.length / (limit + 1))}
        style={{ marginTop: 12, marginRight: 12, float: 'right' }}
      />
    </Panel>
  )
})

export default OrderTable
