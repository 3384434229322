import React from 'react'
import { isEmpty } from 'lodash'
import { Modal, Button } from 'rsuite'
import ModalColumn from './ModalColumn'
import OfflineInfo from './OfflineInfo'

const ConfirmModal = ({ currentInfo, show, handleClose, handleAddAdjustment, isShowCashback }) => {
  const {
    previous_credit_card,
    previous_company,
    previous_cash,
    new_trip_status,
    new_amount,
    new_service_fee,
    new_grading_cashback,
    new_coupon,
    new_fare,
    new_miles,
    new_linego_points,
    new_cash,
    new_credit_card,
    new_company,
    offlineTransaction,
    refund,
    collectable,
    cancelFeeRefund,
    cancelFeeCollectable,
  } = currentInfo

  return (
    <Modal backdrop="static" size="xs" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title style={{ textAlign: 'center' }}>請再次確認修改後的行程金流明細</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="trip-transaction-modal">
          <ModalColumn title="行程狀態" value={new_trip_status} />
          <ModalColumn title="總車資" value={`$${new_amount}`} />
          <ModalColumn title="乘車券" value={`$${new_coupon ? new_coupon.redeem_value : 0}`} />
          <ModalColumn title="司機派遣費" value={`$${new_service_fee}`} />
          {isShowCashback && <ModalColumn title="車資回饋金" value={`$${new_grading_cashback || 0}`} />}
          <ModalColumn title="T里程" value={`${new_miles}T`} />
          {new_linego_points.length > 0 &&
            new_linego_points
              .filter((point) => ['PENDING', 'ISSUED'].includes(point.status) && point.points > 0)
              .map((point) => <ModalColumn title="點數" value={`${point.points}點`} />)}
          <ModalColumn title="應付金額" value={`$${new_fare}`} />
          <div style={{ marginTop: 10, marginLeft: 15, borderTop: '2px solid #eee' }}>
            {previous_credit_card !== new_credit_card && (
              <ModalColumn title="調整刷卡金額" previous={`$${previous_credit_card}`} newValue={new_credit_card.toString()} />
            )}
            {previous_cash !== new_cash && <ModalColumn title="調整現金" previous={`$${previous_cash}`} newValue={new_cash.toString()} />}
            {!isEmpty(offlineTransaction) && (
              <ModalColumn title="路招付款">
                <OfflineInfo transaction={offlineTransaction} />
              </ModalColumn>
            )}
            {previous_company !== new_company && (
              <ModalColumn title="調整企業簽單金額" previous={`$${previous_company}`} newValue={new_company.toString()} />
            )}
            {!!refund && <ModalColumn title="應退刷金額" value={`退 -$${refund}`} style={{ color: 'red' }} />}
            {!!collectable && <ModalColumn title="應補刷金額" value={`收 $${collectable}`} style={{ color: 'green' }} />}
            {!!cancelFeeRefund && <ModalColumn title="應退刷金額(取消費)" value={`退 -$${cancelFeeRefund}`} style={{ color: 'red' }} />}
            {!!cancelFeeCollectable && <ModalColumn title="應補刷金額(取消費)" value={`收 $${cancelFeeCollectable}`} style={{ color: 'green' }} />}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClose} appearance="subtle">
          取消
        </Button>
        <Button onClick={handleAddAdjustment} appearance="primary">
          確認送出
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(ConfirmModal)
