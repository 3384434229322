/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { isEmpty, find, toUpper } from 'lodash'
import { Alert, Modal, Button, Input, Toggle, InputPicker } from 'rsuite'
import ModalColumn from './ModalColumn'
import OfflineInfo from './OfflineInfo'
import { handleTransactions } from '../../pages/trip_transaction/helper'
import { FormGrop, DropdownGroup } from '../views'
import { NewPopWarning } from '../popWarning'
import { createModel } from '../../utils'
import { calculateFareAdjustment, getReasonSuggestion } from '../../api'

const initPaymentMethod = { method: null, value: null }
const initEstimateData = { amount: 0, coupon_id: 0, cash: 0, order_id: null }
const CALCULATE_RULE = {
  NO_COUPON: 'NO_COUPON',
  NO_CASH: 'NO_CASH',
  NO_ORDER_ID: 'NO_ORDER_ID',
  ADD_CASH: 'ADD_CASH',
  ADD_ORDER_ID: 'ADD_ORDER_ID',
  CANCEL_TRIP: 'CANCEL_TRIP',
  CHANGE_TRIP_STATUS: 'CHANGE_TRIP_STATUS',
}
const PAYMENT_OPTIONS = [
  {
    name: '現金支付',
    value: 'cash',
    hint: '請輸入已付款現金金額',
  },
  {
    name: '路招刷卡',
    value: 'order_id',
    hint: '請輸入路招刷卡order id',
  },
]

const TRIP_STATUS_OPTIONS = [
  {
    label: 'TRIP_PAYMENT_PROCESSED',
    value: 'TRIP_PAYMENT_PROCESSED',
  },
  {
    label: 'TRIP_CANCELED',
    value: 'TRIP_CANCELED',
  },
]

const TripTransactionModal = ({
  currentInfo,
  show,
  handleClose,
  handleOnChange,
  setCurrentInfo,
  setConfirmation,
  isShowCashback,
  isDuringProhibted,
}) => {
  const {
    trip_id,
    previous_trip_status,
    previous_payment_method,
    previous_amount,
    previous_service_fee,
    previous_grading_cashback,
    previous_coupon,
    previous_discount,
    previous_fare,
    previous_miles,
    previous_linego_points,
    previous_cash,
    previous_credit_card,
    previous_company,
    previous_cancel_fee,
    new_amount,
    new_service_fee,
    new_grading_cashback,
    new_coupon,
    new_discount,
    new_fare,
    new_miles,
    new_linego_points,
    new_cash,
    new_credit_card,
    new_company,
    offlineTransaction,
    refund,
    collectable,
    reason,
    new_trip_status,
    cancelFeeRefund,
    cancelFeeCollectable,
  } = currentInfo // 裡面放目前行程內容和計算過的新金流結果

  const [otherPayment, setOtherPayment] = useState(initPaymentMethod)
  const [estimate, setEstimate] = useState(initEstimateData) // estimate 尚未經計算的新金流設定，例如暫存付款方式、乘車券序號...
  const [suggestions, setSuggestions] = useState([]) // 變更金流的理由選項
  const paymentHint = useMemo(() => {
    const method = find(PAYMENT_OPTIONS, { value: otherPayment.method })
    return method ? method.hint : ''
  }, [otherPayment])

  const withoutCoupon = useMemo(() => {
    const coupon = new_coupon || previous_coupon
    return coupon.coupon_id === null
  }, [new_coupon, previous_coupon])

  // LT-8842 判斷是取消行程狀態
  const isCancelledTrip = useMemo(() => {
    return (new_trip_status || previous_trip_status) === 'TRIP_CANCELED'
  }, [previous_trip_status, new_trip_status])

  const calculateNewFare = async (params = {}) => {
    const body = {
      fare: Number(estimate.amount),
      coupon_id: estimate.coupon_id || (isEmpty(currentInfo.new_coupon) ? previous_coupon.coupon_id : currentInfo.new_coupon.coupon_id),
      cash: Number(estimate.cash) || 0,
      trip_status: new_trip_status || previous_trip_status,
      payment_method: previous_payment_method,
    }

    /*
      這段在整理當下 Action 的規則
      塞新券、退乘車券、改收現金、新增路招刷卡紀錄...
    */
    const currentEstimate = { ...estimate }
    if (estimate.order_id) body.offline_transactions = estimate.order_id
    const { rule, value } = params
    switch (rule) {
      case CALCULATE_RULE.CHANGE_TRIP_STATUS:
        if (value === 'TRIP_PAYMENT_PROCESSED') delete body.cancel_fee
        body.trip_status = value
        break
      case CALCULATE_RULE.CANCEL_TRIP:
        body.trip_status = 'TRIP_CANCELED'
        body.cancel_fee = Boolean(value)
        break
      case CALCULATE_RULE.NO_COUPON:
        delete body.coupon_id
        break
      case CALCULATE_RULE.NO_CASH:
        body.cash = 0
        currentEstimate.cash = 0
        break
      case CALCULATE_RULE.NO_ORDER_ID:
        delete body.offline_transactions
        currentEstimate.order_id = 0
        break
      case CALCULATE_RULE.ADD_CASH:
        body.cash = value
        break
      case CALCULATE_RULE.ADD_ORDER_ID:
        body.offline_transactions = value
        break
      default:
        break
    }
    console.log('---calculateNewFare---', body)

    const response = await calculateFareAdjustment(trip_id, body)
    if (response.status !== 200) {
      const { msg } = response.data
      // error handle: 找不到該筆乘車券 or 路招紀錄，回復原本的 estimate
      if (msg.includes('coupon_id')) setEstimate({ ...estimate, coupon_id: 0 })
      if (msg.includes('offline transaction')) setEstimate({ ...estimate, order_id: 0 })
      return Alert.error(response.data.msg)
    }
    // 拿到 API 計算完的結果
    const { after } = response.data
    const {
      trip_status,
      trip_fare,
      transactions,
      service_fee,
      grading_cashback,
      coupon,
      coupon_id,
      mileage,
      total_cash,
      total_online,
      cancel_fee_transactions,
      total_company,
      linego_points,
    } = after
    const { refund, collectable, offline } = handleTransactions(transactions)
    const { refund: cancelFeeRefund, collectable: cancelFeeCollectable } = handleTransactions(cancel_fee_transactions)
    // 存入新的 estimate 資料（order_id、cash、coupon_id）
    setEstimate({
      ...currentEstimate,
      amount: trip_fare,
      coupon_id: coupon_id || '',
      cash: total_cash,
      order_id: offline.order_id || null,
    })
    setCurrentInfo({
      ...currentInfo,
      new_trip_status: trip_status,
      new_amount: trip_fare,
      new_service_fee: service_fee,
      new_grading_cashback: grading_cashback,
      new_coupon: rule === CALCULATE_RULE.NO_COUPON ? { coupon_id: null, type: 'NO_COUPONS', redeem_value: 0 } : { coupon_id, redeem_value: coupon },
      new_discount: coupon,
      new_fare: trip_fare - coupon, // 總車資扣除乘車券折扣 = 實際要付
      new_miles: mileage || 0,
      new_linego_points: linego_points || [],
      new_cash: total_cash,
      new_credit_card: total_online,
      new_company: total_company,
      offlineTransaction: offline || {},
      refund,
      collectable,
      cancelFeeRefund,
      cancelFeeCollectable,
      calculation: response.data,
    })
  }

  // GET 變更原因列表
  const fetchSuggestions = useCallback(async () => {
    const response = await getReasonSuggestion({ type: 'PAYMENT_REVIEW' })
    if (response.status !== 200) return console.log(response.data.msg)
    const { data } = response
    const suggestions = data.map(({ id, title }) => {
      return { name: title, value: title, id }
    })
    setSuggestions(suggestions)
  }, [])

  const handleOtherPayment = (action, type) => {
    const { method, value } = otherPayment
    // 新增付款方式
    if (action === 'add') {
      if (!value) {
        setOtherPayment(initPaymentMethod)
        return Alert.warning('請輸入付款方式的值')
      }
      setEstimate({ ...estimate, [method]: value })
      calculateNewFare({ rule: CALCULATE_RULE[`ADD_${toUpper(method)}`], value })
      setOtherPayment(initPaymentMethod)
    }

    // 刪除付款方式
    if (action === 'delete') calculateNewFare({ rule: CALCULATE_RULE[`NO_${toUpper(type)}`] })
  }

  const handleApplicationFinish = () => {
    if (new_trip_status !== 'TRIP_CANCELED' && !new_amount) return Alert.error('請確實輸入總車資！')
    if (!reason) return Alert.error('請確實變更原因！')
    setConfirmation(true)
  }

  useEffect(() => {
    fetchSuggestions()
  }, [])

  useEffect(() => {
    if (show) return setEstimate({ ...estimate, amount: previous_amount, cash: previous_cash })
    // init data
    setOtherPayment(initPaymentMethod)
    setEstimate(initEstimateData)
  }, [show, previous_cash])

  return (
    <Modal backdrop="static" size="xs" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title style={{ textAlign: 'center' }}>變更行程金流</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="trip-transaction-modal">
          <h6>行程狀態</h6>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputPicker
              value={new_trip_status || previous_trip_status}
              data={TRIP_STATUS_OPTIONS}
              onChange={(value) => calculateNewFare({ rule: CALCULATE_RULE.CHANGE_TRIP_STATUS, value })}
              cleanable={false}
              style={{ width: '70%' }}
            />
          </div>
          {isCancelledTrip ? (
            <>
              <h6>新增取消費</h6>
              <ModalColumn title="是否新增取消費">
                <Toggle
                  size="md"
                  checked={Boolean(cancelFeeCollectable || previous_cancel_fee)}
                  onChange={(checked) => calculateNewFare({ rule: CALCULATE_RULE.CANCEL_TRIP, value: checked })}
                />
              </ModalColumn>
              {/* LT-12434 修改車資自動化-回饋金 這邊有個tricky，newValue 0不會顯示，所以用字串 ' 0' 強迫他顯示 */}
              {isShowCashback && (
                <>
                  <h6>請確認欲調整的車資回饋金</h6>
                  <ModalColumn title="車資回饋金" previous={`$${previous_grading_cashback ?? 0}`} newValue={` ${new_grading_cashback ?? 0}`} />
                </>
              )}
            </>
          ) : (
            <>
              <h6 className="require">請輸入正確的總車資(必填)</h6>
              <ModalColumn title="總車資" previous={`$${previous_amount}`} newValue={new_amount} />
              <ModalColumn title="司機派遣費" previous={`$${previous_service_fee}`} newValue={new_service_fee} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  placeholder="請在此輸入正確的總車資"
                  type="number"
                  value={estimate.amount}
                  onChange={(value) => setEstimate({ ...estimate, amount: value })}
                />
                <Button size="xs" onClick={calculateNewFare} appearance="ghost">
                  計算
                </Button>
              </div>
              {/* LT-12434 修改車資自動化-回饋金 */}
              {isShowCashback && (
                <>
                  <h6>請確認欲調整的車資回饋金</h6>
                  <ModalColumn title="車資回饋金" previous={`$${previous_grading_cashback ?? 0}`} newValue={new_grading_cashback ?? 0} />
                </>
              )}
              <h6>請確認欲使用的乘車券及折扣金額</h6>
              <div className="coupon-wrapper">
                {!withoutCoupon && (
                  <Button
                    appearance="ghost"
                    size="xs"
                    style={{ position: 'absolute', right: 5, top: 5 }}
                    onClick={() => {
                      createModel(
                        <NewPopWarning
                          createPortal
                          warningTitle="確定退還此張乘車券，並重新計算車資？"
                          paddingStyle="53px 50px 0"
                          isCancel
                          cancelText="返回"
                          confirmText="確認"
                          zIndex="1500"
                          confirm={() => calculateNewFare({ rule: CALCULATE_RULE.NO_COUPON })}
                        />,
                      )
                    }}
                  >
                    退還
                  </Button>
                )}
                <ModalColumn title="Coupon id" previous={(new_coupon ? new_coupon.coupon_id : previous_coupon.coupon_id) || '無'} />
                <ModalColumn title="折扣金額" previous={`$${previous_discount}`} newValue={`${isEmpty(new_coupon) ? '' : new_discount}`} />
              </div>
              {withoutCoupon && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Input
                    placeholder="請輸入coupon id"
                    type="number"
                    value={estimate.coupon_id}
                    onChange={(value) => setEstimate({ ...estimate, coupon_id: value })}
                  />
                  <Button
                    appearance="ghost"
                    size="xs"
                    onClick={() => {
                      if (!estimate.coupon_id) return Alert.warning('請輸入 coupon id')
                      calculateNewFare()
                    }}
                  >
                    塞券
                  </Button>
                </div>
              )}
              <h6>乘客是否已改用其他方式付款?</h6>
              {!estimate.cash && !estimate.order_id && (
                <ModalColumn title="付款方式">
                  <DropdownGroup
                    style={{ width: 'min-Content' }}
                    activeKey={otherPayment.method}
                    options={PAYMENT_OPTIONS}
                    onSelect={(selectMethod) => setOtherPayment({ ...otherPayment, method: selectMethod })}
                  />
                </ModalColumn>
              )}
              {paymentHint && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Input placeholder={paymentHint} type="text" onChange={(value) => setOtherPayment({ ...otherPayment, value })} />
                  <Button appearance="primary" size="xs" onClick={() => handleOtherPayment('add')}>
                    新增
                  </Button>
                </div>
              )}
              {!!estimate.cash && (
                <ModalColumn
                  title="已付現金"
                  value={`$${estimate.cash}`}
                  buttonText="刪除"
                  buttonClick={() => handleOtherPayment('delete', 'cash')}
                />
              )}
              {!!estimate.order_id && (
                <ModalColumn
                  title="路招刷卡 ID"
                  value={`${estimate.order_id}`}
                  buttonText="刪除"
                  buttonClick={() => handleOtherPayment('delete', 'order_id')}
                />
              )}
            </>
          )}
          <h6 className="require">請選擇變更的原因(必填)</h6>
          <ModalColumn title="變更原因" value={find(suggestions, (data) => reason.includes(data.value))?.value || ''}>
            {isEmpty(reason) && <DropdownGroup options={suggestions} onSelect={(value) => handleOnChange(value, 'reason')} />}
          </ModalColumn>
          <FormGrop placeholder="請在此輸入其他原因" type="textarea" value={reason} handleOnChange={handleOnChange} setStateKey="reason" />
          <h6>請確認乘客的應付款金額</h6>
          <ModalColumn title="應付車資" previous={`$${previous_fare}`} newValue={new_fare ? `$${new_fare}` : ''} />
          {!!(previous_credit_card || new_credit_card) && (
            <ModalColumn
              title="已刷卡金額"
              previous={`$${previous_credit_card}`}
              newValue={new_coupon && previous_credit_card !== new_credit_card ? `$${new_credit_card}` : null}
            />
          )}
          {!!(previous_cash || new_cash) && (
            <ModalColumn title="已付現金" previous={`$${previous_cash}`} newValue={new_coupon && previous_cash !== new_cash ? `${new_cash}` : ''} />
          )}
          {!!(previous_company || new_company) && (
            <ModalColumn
              title="企業簽單金額"
              previous={`$${previous_company}`}
              newValue={new_coupon && previous_company !== new_company ? `$${new_company}` : null}
            />
          )}
          {!isEmpty(offlineTransaction) && (
            <ModalColumn title="路招付款">
              <OfflineInfo transaction={offlineTransaction} />
            </ModalColumn>
          )}
          {!!refund && <ModalColumn title="應退刷金額" value={`退 -$${refund}`} style={{ color: 'red' }} />}
          {!!collectable && <ModalColumn title="應補刷金額" value={`收 $${collectable}`} style={{ color: 'green' }} />}
          {!!cancelFeeRefund && <ModalColumn title="應退刷金額(取消費)" value={`退 -$${cancelFeeRefund}`} style={{ color: 'red' }} />}
          {!!cancelFeeCollectable && <ModalColumn title="應補刷金額(取消費)" value={`收 $${cancelFeeCollectable}`} style={{ color: 'green' }} />}
          <ModalColumn title="T里程" previous={previous_miles} newValue={new_coupon ? new_miles.toString() : new_miles} />
          {new_linego_points.length > 0 &&
            new_linego_points.map((point) => (
              <ModalColumn
                title="點數"
                previous={previous_linego_points?.find((previous) => previous.id === point.id)?.points}
                newValue={['PENDING', 'ISSUED'].includes(point.status) ? point.points.toString() : '0'}
              />
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClose} appearance="subtle">
          取消
        </Button>
        <Button appearance="primary" onClick={handleApplicationFinish} disabled={isDuringProhibted}>
          送出審核
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(TripTransactionModal)
