export const subservice = [
  {
    name: '共享',
    value: 'rental',
  },
  {
    name: '門市',
    value: 'srental',
  },
  {
    name: '共享＋門市',
    value: 'all',
  },
]
