import React, { useEffect, useState } from 'react'
import { TagPicker, Alert } from 'rsuite'
import { getSrentalStations } from '../../api'

const SrentalStationPicker = ({ value, setValue }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await getSrentalStations()
        setData(result.data.map((v) => ({ label: v.name, value: v.id })))
      } catch (error) {
        Alert.error(error?.message || 'fetch srental station error')
      }
    }
    fetch()
  }, [])

  return <TagPicker data={data} value={value} onChange={setValue} placeholder="不限門市" cleanable={false} placement="top" style={{ width: 300 }} />
}

export default React.memo(SrentalStationPicker)
