import React, { useEffect, useState } from 'react'
import { TagPicker, Alert } from 'rsuite'
import { getRentalCarSeries } from '../../api'

const CarSeriesPicker = ({ value, setValue, disabled = false, subService }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await getRentalCarSeries()
        const rental = result.data.filter((v) => v.subService === 'rental').map((v) => ({ label: v.carName, value: v.id }))
        const srental = result.data.filter((v) => v.subService === 'srental').map((v) => ({ label: v.name, value: v.id }))
        setData({ rental, srental })
      } catch (error) {
        Alert.error(error?.message || 'fetch car series error')
      }
    }
    fetch()
  }, [])

  return (
    <TagPicker
      disabled={disabled}
      data={data[subService]}
      value={value}
      onChange={setValue}
      placeholder="全部車種"
      cleanable={false}
      placement="top"
      style={{ width: 300 }}
    />
  )
}

export default React.memo(CarSeriesPicker)
