/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react'
import { Loader, RadioGroup, Radio } from 'rsuite'
import { ContextStore } from '../../reducers'
import { FileInput } from '../../components/finance'
import PopWarning from '../../components/popWarning'
import { postTransactionRecord } from '../../api'
import { AddListModel, FormGrop, AddListGrop } from '../../components/views'

const FinanceTransactionsAllocation = () => {
  const { auth } = useContext(ContextStore)
  const [warning, setWarning] = useState('')
  const [userId, setUserId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fileType, setFileType] = useState('linepay')
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (auth && auth.user) setUserId(auth.user.admin_id)
  }, [auth])

  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    if (file) setFile(file)
  }

  const handleSubmit = async () => {
    if (!userId || !file) return

    const formData = new FormData()
    formData.append('uploaded_file', file)
    setLoading(true)
    const response = await postTransactionRecord(fileType, formData)
    setLoading(false)
    if (response.status !== 200) return setWarning(response.data.msg)
    setWarning('上傳成功！請到信箱收取報表')
    setFile(null)
  }

  return (
    <>
      {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')} />}
      <AddListModel>
        {loading && <Loader size="md" center vertical style={{ marginLeft: 260, zIndex: 10 }} />}
        <AddListGrop title="上傳交易紀錄(LINE Pay / 藍新 / 藍新退款)">
          <FormGrop title="檔案類型" type="children">
            <RadioGroup inline value={fileType} onChange={(value) => setFileType(value)}>
              <Radio value="linepay">LINE Pay</Radio>
              <Radio value="newebpay">藍新</Radio>
              <Radio value="newebpay_refund">藍新退款</Radio>
            </RadioGroup>
          </FormGrop>
          <FormGrop title="上傳檔案" type="children">
            <FileInput handleChangeFile={handleChangeFile} />
          </FormGrop>
        </AddListGrop>
        <div className="campaign-button-wrap">
          <button type="button" className="campaign-confirm-button" onClick={handleSubmit}>
            送出
          </button>
        </div>
      </AddListModel>
    </>
  )
}

export default FinanceTransactionsAllocation
