import React, { useEffect, useState, useContext } from 'react'
import { Button, Loader, Modal } from "rsuite"
import { FormGrop } from "../views"
import { FileInput } from "../finance"
import { ContextStore } from "../../reducers"
import { canceledReservationsBatch } from "../../api"
import PopWarning from "../popWarning"

const CancelReservationsBatchModal = React.memo(({show, close }) => {
    const [file, setFile] = useState(null)
    const {auth} = useContext(ContextStore)
    const [userId, setUserId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [warning, setWarning] = useState('')

    useEffect(() => {
        if (auth && auth.user) setUserId(auth.user.admin_id)
    }, [auth])

    const handleChangeFile = (e) => {
        const file = e.target.files[0]
        if (file) setFile(file)
    }

    const handleSubmit = async () => {
        if (!userId || !file) return

        const formData = new FormData()
        formData.append('file', file)
        setLoading(true)
        const response = await canceledReservationsBatch(formData)
        setLoading(false)
        if (response.status !== 200) return setWarning(response.data.msg)
        setWarning('上傳成功！')
        setFile(null)
    }

    return (
        <>
            {warning && <PopWarning warning={warning} textCenter confirm={() => setWarning('')}/>}
            {loading && <Loader size="md" center vertical style={{ marginLeft: 260, zIndex: 10 }} />}
            <Modal backdrop={true} show={show} onHide={close} size="sm">
                <Modal.Header>
                    <Modal.Title>批量釋放預約單</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{marginTop: '30px'}}>
                    <FormGrop title="上傳檔案" type="children">
                        <FileInput handleChangeFile={handleChangeFile}/>
                    </FormGrop>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button onClick={close} appearance="subtle">
                            取消
                        </Button>
                        <Button onClick={handleSubmit} color="red" disabled={!file}>
                            送出
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
})

export default CancelReservationsBatchModal