import React, { useEffect, useState, useCallback } from 'react'
import { DatePicker, Breadcrumb, Input, RadioGroup, Radio, Dropdown, Modal } from 'rsuite'
import { Column, HeaderCell, Cell } from 'rsuite-table'
import dayjs from 'dayjs'
import { isEmpty, isArray } from 'lodash'
import { activityType } from './activityType'
import { getHappyHours, createHappyHours, deleteHappyHours, createHappyHoursAudience, deleteHappyHoursAudience } from '../../api'
import { filterHappyHours, getActivityName } from './helper'
import { transLocation } from '../../utils/map'
import { TitleCell, TypeCell, ActionCell, Toggle } from '../../components/happyhours'
import { MapCell } from '../../components/map'
import ListWrap from '../../components/views/ListWrap'
import { PreviewMap, GeoGenerator } from '../../components/map'
import AddListModel from '../../components/views/AddListModel'
import FormGrop from '../../components/views/FormGrop'
import FormGropWrap from '../../components/views/FormGropWrap'
import AddListGrop from '../../components/views/AddListGrop'
import PopWarning from '../../components/popWarning'
import UserGroupTable from '../dama/UserGroupTable'
import { TableController, MoreButton, SearchInput } from '../../components/table'
import "react-datepicker/dist/react-datepicker.css"

const limit = 10
const initCurrentInfo = {
	id: null,
	activityType: 'NORMAL',
	title: '',
	coupon: '',
	startDate: '',
	endDate: '',
	areaType: 'location',
	startAddress: '',
	endAddress: '',
	startLocation: [],
	endLocation: [],
	titleError: '',
	couponError: '',
	startDateError: '',
	endDateError: '',
	startAddressError: '',
	endAddressError: '',
	startLocationError: '',
	endLocationError: '',
	audienceSettings: { userGroups: [], action: 'disable' },
	isPopup: false,
	damaUserGroups: [],
}

const HappyHours = () => {
	const [isAddMode, setAddMode] = useState(false)
	const [inProgress, setInProgress] = useState(false)   // 是否只顯示目前活動
	const [searchWord, setSearchWord] = useState('')      // 關鍵字搜尋
	const [editLocation, setEditLocation] = useState('')  // 跳出座標設置/區域的 stateKey
	const [allData, setAllData] = useState([])            // 全部的資料
	const [showingData, setShowingData] = useState([])    // 目前展示的資料，避免一次 render 太多地圖，API 無分頁功能，所以先由前端處理
	const [requireNum, setRequireNum] = useState(limit)   // 要求展示的數量
	const [warning, setWarning] = useState('')
	const [showMoreButton, setMoreButton] = useState(true)
  const [currentInfo, setCurrentInfo] = useState(initCurrentInfo)

	const fetchHappyHours = async () => {
		const response = await getHappyHours()
		if(isEmpty(response) || !isArray(response.data)) return
		setAllData(response.data.sort((a,b) => b.id - a.id))
	}

	const toggleAddMode = () => {
    setAddMode(!isAddMode)
  }

	const handlePostActivity = async () => {
		const { title, activityType, areaType, startDate, endDate, coupon, startAddress, endAddress, startLocation, endLocation, audienceSettings, isPopup } = currentInfo
		setCurrentInfo({
			...currentInfo,
			titleError: !title? '請填寫或選擇項目': '',
			couponError: !coupon? '請填寫或選擇項目': '',
			startDateError: !startDate? '請填寫或選擇項目': '',
			endDateError: !endDate? '請填寫或選擇項目': '',
		})
		if (!title || !coupon || !startDate || !endDate) return setWarning('欄位不能為空！')

		// check if area is empty
		const hasEmptyAddress =  isEmpty(startAddress) && isEmpty(endAddress)
		const hasEmptyLocation = isEmpty(startLocation) && isEmpty(endLocation)
		if (areaType === 'address' && hasEmptyAddress) return setWarning('請填入上車或下車地址')
		if (areaType === 'location' && hasEmptyLocation) return setWarning('請填入上車或下車範圍')

		// call api
		const body = {
			title: title,
			start_time: startDate,
			end_time: endDate,
			type: activityType,
			coupon: coupon,
			start_address: areaType === 'address' && !isEmpty(startAddress) ? startAddress : null,
			end_address: areaType === 'address' && !isEmpty(endAddress) ? endAddress : null,
			start_location: areaType === 'location' && !isEmpty(startLocation)?  startLocation : null,
			end_location: areaType === 'location' && !isEmpty(endLocation)?  endLocation : null,
			audience_settings: audienceSettings,
			is_popup: isPopup,
		}

		const response = await createHappyHours(body)
		if (response.status !== 200) return setWarning('新增失敗，請稍候再嘗試')
		createHappyHoursAudience(response.data.insertId)
		setWarning('新增成功！')
		toggleAddMode()
	}

	const handleCopyActivity = (data) => {
		const { title, coupon, start_time, end_time, start_address, end_address, start_location, end_location, type, audience_settings, is_popup, dama_user_groups } = data
		const isAddress = !isEmpty(start_address) || !isEmpty(end_address)
		setCurrentInfo({
			id: null,
			activityType: type,
			title: title,
			coupon: coupon,
			startDate: start_time || '',
			endDate: end_time || '',
			areaType: isAddress ? 'address' : 'location',
			startAddress: start_address || '',
			endAddress: end_address || '',
			startLocation: transLocation(start_location) || [],
			endLocation: transLocation(end_location) || [],
			audienceSettings: audience_settings || { userGroups: [], action: 'disable' },
			isPopup: !!is_popup,
			damaUserGroups: dama_user_groups || [],
			titleError: '',
			couponError: '',
			startDateError: '',
			endDateError: '',
			startAddressError: '',
			endAddressError: '',
			startLocationError: '',
			endLocationError: '',
		})
	}

	const handleDeleteActivity = async (activityId) => {
		const body = { data: { event_id: activityId } }
		const response = await deleteHappyHours(body)
		if (response && response.status !== 200) return setWarning('刪除失敗，請稍候再嘗試')
		deleteHappyHoursAudience(activityId)
		setWarning('刪除成功')
	}

	const handleActivitySelect = (eventKey) => {
		if (eventKey === currentInfo.activityType) return
		setCurrentInfo({
			...currentInfo,
			areaType: 'location',
			activityType: eventKey
		})
	}

	const setSelectedUserGroupIds = (ids) => {
		setCurrentInfo({
			...currentInfo,
			audienceSettings: { ...currentInfo.audienceSettings, userGroups: ids }
		})
	}

	const handleDatePicker = (date, stateKey) => {
    setCurrentInfo({
      ...currentInfo,
      [stateKey]: dayjs(+date).unix()
    })
	}

	const handleOnChange = (e, setStateKey) => {
		const value = e.target ? e.target.value : e
		setCurrentInfo({
      ...currentInfo,
      [setStateKey]: value
    })
	}

	const HandleAudienceSettings = (e, setStateKey) => {
		const value = e.target ? e.target.value : e
		setCurrentInfo({
			...currentInfo,
			audienceSettings: { ...currentInfo.audienceSettings, [setStateKey]: value }
		})
	}

	const handleWarningConfirm = () => {
		setWarning('')
		setAllData([])
		fetchHappyHours()
	}

	const handleClose = useCallback(() => {
    setEditLocation('')
  }, [])

	// init
	useEffect(() => {
		fetchHappyHours()
	}, [])

	// reset add mode
	useEffect(() => {
		if (isAddMode) return
		setCurrentInfo(initCurrentInfo)
	}, [isAddMode])

	// update showing data
	useEffect(() => {
		const filterData = filterHappyHours(searchWord, inProgress, allData, requireNum)
		const isHidden = filterData.length !== 0 && requireNum > filterData.length
		isHidden? setMoreButton(false) : setMoreButton(true)
		setShowingData(filterData)
	}, [searchWord, inProgress, allData, requireNum])

return (
	<>
		<div style={{ width: '100%'}}>
		{!isEmpty(editLocation) && <Modal size={'lg'} show={!isEmpty(editLocation)} onHide={handleClose} >
			<GeoGenerator
				type="range"
				value={editLocation === 'startLocation'? currentInfo.startLocation : currentInfo.endLocation}
				handleClose={handleClose}
				setStateKey={editLocation}
				setEditLocation={setEditLocation}
				handleOnChange={handleOnChange}/>
		</Modal>}
		{warning && <PopWarning warning={warning} textCenter={true} confirm={handleWarningConfirm} />}
		{isAddMode ? (
			<AddListModel >
				<Breadcrumb>
					<Breadcrumb.Item onClick={toggleAddMode}>Happy Hours</Breadcrumb.Item>
					<Breadcrumb.Item active>設置活動</Breadcrumb.Item>
				</Breadcrumb>
				<AddListGrop title={'新增 Happy Hours 活動'}>
					{currentInfo.id  && <FormGrop title={'活動 ID'} type='children'><Input value={currentInfo.id} disabled /></FormGrop>}
					<FormGrop title={'活動類型'} type={'children'}>
						<Dropdown
							title={getActivityName(currentInfo.activityType)}
							activeKey={currentInfo.activityType}
							menuStyle={{width: '100%'}}
							onSelect={handleActivitySelect}>
							{activityType.map((item, index) => {
								return (<Dropdown.Item key={index} eventKey={item.value} children={item.name} />)
							})}
						</Dropdown>
					</FormGrop>
					<FormGrop title={'活動名稱*'} placeholder={'Title'} type={'input'} value={currentInfo.title} handleOnChange={handleOnChange} setStateKey={'title'} errorMessage={currentInfo.titleError} />
					<FormGropWrap>
						<FormGrop title={'活動開始'} type={'children'} errorMessage={currentInfo.startDateError}>
							<DatePicker
								defaultValue={currentInfo.startDate ? new Date(currentInfo.startDate * 1000) : undefined}
								onOk={(date) => handleDatePicker(date, 'startDate')}
								placement={'bottomStart'}
								appearance={'subtle'}
								placeholder={'選擇日期時間'}
								isoWeek
								hideMinutes={second => second % 5 !== 0}
								format={'YYYY-MM-DD HH:mm'}
								ranges={[{ value: new Date(), closeOverlay: true }]} />
						</FormGrop>
						<FormGrop title={'活動結束'} type={'children'} errorMessage={currentInfo.endDateError}>
							<DatePicker
								defaultValue={currentInfo.endDate ? new Date(currentInfo.endDate * 1000) : undefined}
								onOk={(date) => handleDatePicker(date, 'endDate')}
								placement={'bottomStart'}
								placeholder={'選擇日期時間'}
								appearance={'subtle'}
								isoWeek
								hideMinutes={second => second % 5 !== 0}
								format={'YYYY-MM-DD HH:mm'}
								ranges={[{ value: new Date(), closeOverlay: true }]} />
						</FormGrop>
					</FormGropWrap>
					<FormGrop title={'優惠券代碼*'} placeholder={'Coupon ID'} type={'input'} value={currentInfo.coupon} handleOnChange={handleOnChange} setStateKey={'coupon'} errorMessage={currentInfo.couponError} />
					<FormGrop title={'活動受眾'} type={'children'}>
						<RadioGroup
							value={currentInfo.audienceSettings.action}
							onChange={value => {HandleAudienceSettings(value, 'action')}}
						>
							<Radio value="disable">無限制</Radio>
							<Radio value="include">限定以下DAMA+分眾名單, 可參與活動</Radio>
							<Radio value="exclude">排除以下DAMA+分眾名單, 不可參與活動</Radio>
						</RadioGroup>
					</FormGrop>
					<div className={currentInfo.audienceSettings.action === 'disable' ? 'disabled-block' : null}>
						<UserGroupTable selectedIds={currentInfo.audienceSettings.userGroups} setSelectedIds={setSelectedUserGroupIds} selectedGroups={currentInfo.damaUserGroups} />
					</div>
					{currentInfo.activityType !== 'SHUTTLE' && <FormGrop title={'活動區域'} type={'children'}>
						<RadioGroup
							inline
							value={currentInfo.areaType}
							onChange={value => {handleOnChange(value, 'areaType')}}
						>
							<Radio value="location">特定範圍</Radio>
							<Radio value="address" disabled={currentInfo.activityType === 'FIRST_TIME'}>指定地址</Radio>
							<Radio value="unlimited" disabled={false}>不限範圍</Radio>
						</RadioGroup>
					</FormGrop>}
					{currentInfo.areaType === 'address' && (
						<FormGropWrap>
								<FormGrop title={'上車地址'} placeholder={'Start Address'} type={'input'} value={currentInfo.startAddress} handleOnChange={handleOnChange} setStateKey={'startAddress'} errorMessage={currentInfo.startAddressError} />
								<FormGrop title={'下車地址'} placeholder={'End Address'} type={'input'} value={currentInfo.endAddress} handleOnChange={handleOnChange} setStateKey={'endAddress'} errorMessage={currentInfo.endAddress} />
						</FormGropWrap>)}
					{currentInfo.areaType === 'location' && (
						<div>
							<PreviewMap
								title={'上車範圍'}
								mapId={'start-map'}
								latlngs={currentInfo.startLocation}
								setEditLocation={setEditLocation}
								setStateKey={'startLocation'}
							/>
							<PreviewMap
								title={'下車範圍'}
								mapId={'end-map'}
								latlngs={currentInfo.endLocation}
								setEditLocation={setEditLocation}
								setStateKey={'endLocation'}
							/>
						</div>
					)}
				</AddListGrop>
				<div className="campaign-button-wrap">
					<button className="campaign-confirm-button" onClick={handlePostActivity}>{currentInfo.id ? '更新' : '新增'}</button>
					<button className="campaign-cancel-button" onClick={toggleAddMode}>取消</button>
				</div>
			</AddListModel>
		) : (
			<div>
				<ListWrap status={'Happy Hours'} addListText={'+ 新增活動'} onClick={toggleAddMode}>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Toggle inProgress={inProgress} setInProgress={setInProgress}/>
						<SearchInput setSearchWord={setSearchWord}/>
					</div>
				</ListWrap>
				<TableController data={showingData} topHeight={104} rowHeight={230}>
					<Column minWidth={80} flexGrow={0}>
						<HeaderCell>活動類型</HeaderCell>
						<TypeCell />
					</Column>
					<Column width={100} flexGrow={0} align="center">
						<HeaderCell>活動 ID</HeaderCell>
						<Cell dataKey="id" />
					</Column>
					<Column minWidth={120} flexGrow={2}>
						<HeaderCell>活動標題</HeaderCell>
						<TitleCell dataKey="title" />
					</Column>
					<Column width={120} flexGrow={0} align="center">
						<HeaderCell>優惠券 ID</HeaderCell>
						<Cell dataKey="coupon" />
					</Column>
					<Column minWidth={80} flexGrow={3}>
						<HeaderCell>開始時間</HeaderCell>
						<Cell>
							{(rowData) => rowData.start_time? dayjs(rowData.start_time * 1000).format('YY-MM-DD HH:mm'): dayjs(rowData.created_at * 1000).format('YY-MM-DD HH:mm')}
						</Cell>
					</Column>
					<Column minWidth={80} flexGrow={3}>
						<HeaderCell>結束時間</HeaderCell>
						<Cell>
							{(rowData) => rowData.end_time? dayjs(rowData.end_time * 1000).format('YY-MM-DD HH:mm'): '未設定'}
						</Cell>
					</Column>
					<Column minWidth={250} flexGrow={2} align="center">
						<HeaderCell>上車範圍</HeaderCell>
						<MapCell dataKey="start_location" />
					</Column>
					<Column minWidth={250} flexGrow={2} align="center">
						<HeaderCell>下車範圍</HeaderCell>
						<MapCell dataKey="end_location" />
					</Column>
					<Column minWidth={100} flexGrow={2}>
						<HeaderCell>上車地址</HeaderCell>
						<TitleCell dataKey="start_address" />
					</Column>
					<Column minWidth={100} flexGrow={2}>
						<HeaderCell>下車地址</HeaderCell>
						<TitleCell dataKey="end_address" />
					</Column>
					<Column width={140} align="center">
						<HeaderCell>複製 / 刪除</HeaderCell>
						<ActionCell dataKey="id" toggleAddMode={toggleAddMode} handleCopyActivity={handleCopyActivity} handleDeleteActivity={handleDeleteActivity}/>
					</Column>
				</TableController>
				{showMoreButton && <MoreButton onClick={() => setRequireNum(requireNum + limit)} />}
			</div>
			)}
		</div>
	</>
	)
}

export default HappyHours
