import { useEffect, useMemo, useState } from 'react'
import { Alert } from 'rsuite'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { cloneDeep, omit } from 'lodash'
import { mixed, number, object, string, array } from 'yup'
import { getCouponCategories, getCouponTypes } from '../../api'

dayjs.extend(customParseFormat)

export const COUPON_FORM_FEATURES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DUPLICATE: 'DUPLICATE',
}

const promoTypeOptions = [
  {
    label: '重複領用',
    value: 'OPERATION',
  },
  {
    label: '首次乘車',
    value: 'FIRST_TIME_USER',
  },
  {
    label: '單次領用',
    value: 'ONE_TIME_EVENT',
  },
  {
    label: '亂碼序號',
    value: 'SERIAL_NUMBER',
  },
]

const discountTypes = {
  normal: 'normal',
  line_points: 'line_points',
}

const discountWays = {
  fixed_amount: 'fixed_amount',
  percent_off: 'percent_off',
  fixed_line_points: 'fixed_line_points',
  line_point_ratio: 'line_point_ratio',
}

const lifespanTypes = {
  all: 'all',
  designated_daily_timeframe: 'designated_daily_timeframe',
  designated_weekly_timeframe: 'designated_weekly_timeframe',
}

export const carTypes = [
  {
    label: '多元',
    value: '8388608',
  },
  {
    label: '小黃',
    value: '1048576',
  },
  {
    label: '星選',
    value: '1',
  },
  {
    label: '租賃',
    value: '67108864',
  },
]

export const carFunctionalTypes = [
  {
    label: '六人',
    value: '1073741824',
  },
  {
    label: '豪華',
    value: '536870912',
  },
  {
    label: '寶寶',
    value: '512',
  },
  {
    label: '寵物',
    value: '2',
  },
  {
    label: '無障礙',
    value: '64',
  },
  {
    label: '一般',
    value: 'as_car_type',
  },
  {
    label: '減碳',
    value: '1024',
  },
]

export const ondemandTypes = [
  {
    label: '預約',
    value: 0,
  },
  {
    label: '即時',
    value: 1,
  },
  {
    label: '預約+即時',
    value: 2,
  },
]

const airportAreas = {
  kaohsiung: [
    {
      lat: 22.58247304423391,
      lng: 120.3306762183618,
    },
    {
      lat: 22.56907916782606,
      lng: 120.3330797907579,
    },
    {
      lat: 22.56789029933467,
      lng: 120.3512761785219,
    },
    {
      lat: 22.57121910527308,
      lng: 120.3525611338984,
    },
    {
      lat: 22.57169464241648,
      lng: 120.3703313428489,
    },
    {
      lat: 22.58247304423391,
      lng: 120.3714486447695,
    },
    {
      lat: 22.58247304423391,
      lng: 120.3306762183618,
    },
  ],
  taichung: [
    {
      lat: 24.26019113239159,
      lng: 120.600079148251,
    },
    {
      lat: 24.25014040288461,
      lng: 120.5947557042286,
    },
    {
      lat: 24.24771559434547,
      lng: 120.6029125942628,
    },
    {
      lat: 24.25843133440339,
      lng: 120.6055313852738,
    },
    {
      lat: 24.26019113239159,
      lng: 120.600079148251,
    },
  ],
  taoyuan: [
    {
      lat: 25.08700581573351,
      lng: 121.2385889513389,
    },
    {
      lat: 25.08117848366432,
      lng: 121.2447710153647,
    },
    {
      lat: 25.07099941158405,
      lng: 121.2312048193079,
    },
    {
      lat: 25.07651641827488,
      lng: 121.2249368932817,
    },
    {
      lat: 25.08700581573351,
      lng: 121.2385889513389,
    },
  ],
  taipei: [
    {
      lat: 25.06822867318967,
      lng: 121.5469438094686,
    },
    {
      lat: 25.06772357126787,
      lng: 121.5589644895189,
    },
    {
      lat: 25.06480948104999,
      lng: 121.5575048355128,
    },
    {
      lat: 25.06399352336689,
      lng: 121.5547572515013,
    },
    {
      lat: 25.06205074510558,
      lng: 121.5540274244983,
    },
    {
      lat: 25.06166218575658,
      lng: 121.5499919104814,
    },
    {
      lat: 25.06636367112619,
      lng: 121.5457417414636,
    },
    {
      lat: 25.06822867318967,
      lng: 121.5469438094686,
    },
  ],
}

const getDiscountWayWithValues = (values) => {
  if (values.coupon_is_line_point_type) {
    if (values.line_point_coupon_settings.feedback_amount) {
      return discountWays.fixed_line_points
    }
    return discountWays.line_point_ratio
  }
  if (values.max_amount) {
    return discountWays.percent_off
  }
  return discountWays.fixed_amount
}

const getFeatureMapGroupWithValues = (values) => {
  if (!Array.isArray(values.coupon_settings_group)) {
    return []
  }
  return values.coupon_settings_group.reduce((prev, current) => {
    const functionalFeatureMapArray = current.functional_feature_map.split(',')
    const pureFunctionalFeatureMapArray = functionalFeatureMapArray.filter((row) => !carTypes.some((item) => item.value === row))
    const carTypeInFunctionalFeatureMap = functionalFeatureMapArray.find((row) => carTypes.some((item) => item.value === row))
    if (carTypeInFunctionalFeatureMap) {
      pureFunctionalFeatureMapArray.push('as_car_type')
    }
    const item = {
      id: current.id,
      coupon: current.coupon,
      cartype_feature_map: current.cartype_feature_map,
      functional_feature_map: pureFunctionalFeatureMapArray.join(','),
      is_ondemand: current.is_ondemand,
      startLocation: {},
      endLocation: {},
      designated_areas_shadow: [],
    }
    if (Array.isArray(current.designated_areas)) {
      current.designated_areas.forEach((elem) => {
        item.designated_areas_shadow.push(elem)
        if (Array.isArray(elem.start_location)) {
          elem.start_location.forEach((row) => {
            item.startLocation[elem.id] = row.map((pos) => ({ lat: pos.x, lng: pos.y }))
          }, [])
        }
        if (Array.isArray(elem.end_location)) {
          elem.end_location.forEach((row) => {
            item.endLocation[elem.id] = row.map((pos) => ({ lat: pos.x, lng: pos.y }))
          }, [])
        }
      })
    }
    prev.push(item)
    return prev
  }, [])
}

const getLifespanTypeWithValues = (values) => {
  if (values.designatedDailyTimeframe.length) {
    return lifespanTypes.designated_daily_timeframe
  }
  if (values.designatedWeeklyTimeframe.length) {
    return lifespanTypes.designated_weekly_timeframe
  }

  return lifespanTypes.all
}

const getLifespanWeeklyItem = (designatedWeeklyTimeframe) => {
  if (Array.isArray(designatedWeeklyTimeframe)) {
    return designatedWeeklyTimeframe[designatedWeeklyTimeframe.length - 1]
  }
  return {}
}

const getLifespanWeeklyDayOfWeekWithValues = (values) => {
  const result = []
  if (getLifespanTypeWithValues(values) === lifespanTypes.designated_weekly_timeframe) {
    const data = getLifespanWeeklyItem(values.designatedWeeklyTimeframe).day_of_week
    Object.keys(data).forEach((key) => {
      if (data[key] === 1) {
        result.push(key)
      }
    })
  }

  return result
}

const getLifespanWeeklyPeriodsWithValues = (values) => {
  const result = []
  if (getLifespanTypeWithValues(values) === lifespanTypes.designated_weekly_timeframe) {
    const data = getLifespanWeeklyItem(values.designatedWeeklyTimeframe).hours_setting || []
    data.forEach((item) => {
      result.push({ time_of_day_start: item.time_of_day_start, time_of_day_end: item.time_of_day_end })
    })
  }

  return result
}

const getLifespanDailyPeriodsWithValues = (values) => {
  const result = []
  if (getLifespanTypeWithValues(values) === lifespanTypes.designated_daily_timeframe) {
    if (Array.isArray(values.designatedDailyTimeframe)) {
      values.designatedDailyTimeframe.forEach((item) => {
        result.push({ time_of_day_start: item.time_of_day_start, time_of_day_end: item.time_of_day_end })
      })
    }
  }

  return result
}

const generateDefaultValues = (feature, initialValues) => {
  const origin = {
    unlimited_number_of_coupons: true,
    number_of_coupons: 1,
    // For 類型選擇
    discount_type: discountTypes.normal,
    // For 折扣方式 | 回饋方式
    discount_way: discountWays.fixed_amount,
    fixed_amount: 50,
    percent_off_amount: 0.9,
    percent_off_max_amount: undefined,
    minimum_charge_toggle: false,
    valid_days_toggle: false,
    invalid_date: undefined,
    expire_in_days: 1,
    line_point_coupon_settings_shadow: {
      feedback_amount: 10,
      feedback_ratio: 0,
      max_amount: null,
    },
    lifespan_type: lifespanTypes.all,
    lifespan_daily_periods: [],
    designated_daily_timeframe_shadow: [],
    lifespan_weekly_periods: [],
    lifespan_weekly_interval: 1,
    lifespan_weekly_day_of_week: [],
    feature_map_group: [],

    // API 真正需要的部分
    promo_type: promoTypeOptions[0].value,
    promo_title: undefined,
    promo_code: undefined,
    promo_desc: undefined,
    start_date: undefined,
    end_date: undefined,
    count: 1,
    total_number_of_coupons: -1,
    amount: 50,
    max_amount: null,
    coupon_is_line_point_type: false,
    line_point_coupon_settings: {
      feedback_amount: 0,
      feedback_ratio: 0,
      max_amount: null,
    },
    is_for_cash: 0,
    minimum_charge: 0,
    enable_date: undefined,
    valid_days: undefined,
    designated_daily_timeframe: undefined,
    designated_weekly_timeframe: undefined,
    type: undefined,
    category: undefined,
    coupon_settings_group: undefined,
  }
  if (!initialValues) return origin

  const result = cloneDeep(origin)

  result.promo_type = initialValues.promo_type
  result.promo_title = initialValues.promo_title
  result.promo_code = feature === COUPON_FORM_FEATURES.DUPLICATE ? origin.promo_code : initialValues.promo_code
  result.promo_desc = initialValues.promo_desc
  result.start_date = initialValues.start_date
  result.end_date = initialValues.end_date
  result.count = initialValues.count
  result.amount = initialValues.amount
  result.max_amount = initialValues.max_amount
  result.coupon_is_line_point_type = initialValues.coupon_is_line_point_type
  result.line_point_coupon_settings = initialValues.line_point_coupon_settings
  result.is_for_cash = initialValues.is_for_cash
  result.minimum_charge = initialValues.minimum_charge
  result.coupon_settings_group = initialValues.coupon_settings_group
  result.enable_date = initialValues.enable_date
  result.designated_daily_timeframe = initialValues.designatedDailyTimeframe
  result.designated_weekly_timeframe = initialValues.designatedWeeklyTimeframe
  result.type = initialValues.type
  result.category = initialValues.category
  result.valid_days = initialValues.valid_days

  // For form needs
  result.discount_type = initialValues.coupon_is_line_point_type ? discountTypes.line_points : discountTypes.normal
  result.unlimited_number_of_coupons = initialValues.total_number_of_coupons === -1
  result.number_of_coupons = initialValues.total_number_of_coupons > 0 ? initialValues.total_number_of_coupons : origin.number_of_coupons
  result.discount_way = getDiscountWayWithValues(initialValues)
  result.fixed_amount = getDiscountWayWithValues(initialValues) === discountWays.fixed_amount ? initialValues.amount : origin.fixed_amount
  result.percent_off_amount = getDiscountWayWithValues(initialValues) === discountWays.percent_off ? initialValues.amount : origin.percent_off_amount
  result.percent_off_max_amount =
    getDiscountWayWithValues(initialValues) === discountWays.percent_off ? initialValues.max_amount : origin.percent_off_max_amount
  result.line_point_coupon_settings_shadow = {
    feedback_amount:
      getDiscountWayWithValues(initialValues) === discountWays.fixed_line_points
        ? initialValues.line_point_coupon_settings.fixed_amount
        : origin.line_point_coupon_settings_shadow.feedback_amount,
    feedback_ratio:
      getDiscountWayWithValues(initialValues) === discountWays.line_point_ratio
        ? initialValues.line_point_coupon_settings.feedback_ratio
        : origin.line_point_coupon_settings_shadow.feedback_ratio,
    max_amount:
      getDiscountWayWithValues(initialValues) === discountWays.line_point_ratio
        ? initialValues.line_point_coupon_settings.max_amount
        : origin.line_point_coupon_settings_shadow.max_amount,
  }
  result.minimum_charge_toggle = initialValues.minimum_charge > 0
  result.feature_map_group = getFeatureMapGroupWithValues(initialValues)
  result.valid_days_toggle = !dayjs.unix(initialValues.valid_days).isAfter(dayjs('2000-01-01').toDate())
  result.expire_in_days = result.valid_days_toggle ? initialValues.valid_days : origin.valid_days
  result.invalid_date = !result.valid_days_toggle ? initialValues.valid_days : origin.invalid_date
  result.lifespan_type = getLifespanTypeWithValues(initialValues)
  result.lifespan_weekly_interval =
    result.lifespan_type === lifespanTypes.designated_weekly_timeframe
      ? getLifespanWeeklyItem(initialValues.designatedWeeklyTimeframe).interval
      : origin.lifespan_weekly_interval
  result.lifespan_weekly_day_of_week = getLifespanWeeklyDayOfWeekWithValues(initialValues)
  result.lifespan_weekly_periods = getLifespanWeeklyPeriodsWithValues(initialValues)
  result.lifespan_daily_periods = getLifespanDailyPeriodsWithValues(initialValues)
  result.designated_daily_timeframe_shadow = initialValues.designatedDailyTimeframe

  return result
}

const generateCode = (num) => {
  const list = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789'
  let code = ''
  for (let i = 0; i < num; i += 1) {
    const word = Math.floor(Math.random() * list.length)
    code += list.charAt(word)
  }
  return code
}

const dateTransform = { in: (value) => (value ? dayjs.unix(value).toDate() : value), out: (value) => dayjs(value).unix() }

const formatDateTransform = {
  in: (value) => (typeof value === 'string' ? dayjs(value, 'HH:mm:ss').toDate() : value),
  out: (value) => dayjs(value).format('HH:mm:ss'),
}

const numberTransform = {
  in: (value) => value,
  out: (value) => Number(value),
}

const featureMapTransform = {
  in: (value) => (typeof value === 'string' ? value.split(',').filter((unit) => unit.length) : value),
  out: (value) => (Array.isArray(value) && Array.length > 0 ? value.join(',') : value),
}

const locationTransform = {
  in: (value) => value,
  out: (prevValues, value) => (value === Object(value) ? { ...prevValues, ...value } : value),
}

const filterFormValuesForSubmit = (values) => {
  return omit(values, [
    'unlimited_number_of_coupons',
    'number_of_coupons',
    'discount_type',
    'discount_way',
    'fixed_amount',
    'percent_off_amount',
    'percent_off_max_amount',
    'minimum_charge_toggle',
    'valid_days_toggle',
    'invalid_date',
    'expire_in_days',
    'line_point_coupon_settings_shadow',
    'lifespan_type',
    'lifespan_daily_periods',
    'lifespan_weekly_periods',
    'lifespan_weekly_interval',
    'lifespan_weekly_day_of_week',
    'feature_map_group',
    'designated_daily_timeframe_shadow',
  ])
}

const schema = object({
  promo_type: mixed()
    .oneOf(
      promoTypeOptions.map((row) => row.value),
      '適用活動類型格式錯誤',
    )
    .required('適用活動類型不能空白'),
  promo_title: string().required('優惠券名稱不能空白'),
  promo_code: string().required('優惠代碼不能空白'),
  promo_desc: string(),
  start_date: number().required('開始領取時間不能空白'),
  end_date: number().required('結束領取時間不能空白'),
  count: number().min(1, '每次領取張數不能低於 1').required('每次領取張數不能空白'),
  total_number_of_coupons: number().required('發出總張數不能空白'),
  enable_date: number().required('活動開始時間不能空白'),
  valid_days: number().required('請填寫限制天數或結束使用時間'),
  minimum_charge: number().when('coupon_settings_group', {
    is: (value) => {
      return value.some((row) => {
        if (row.cartype_feature_map) {
          // 是否有小黃車
          return row.cartype_feature_map.includes('1048576')
        }
        return false
      })
    },
    then: (schema) => schema.lessThan(1, 'Feature Map 有小黃計程車則不能有低消'),
    otherwise: (schema) => schema.min(0),
  }),
  coupon_settings_group: array(
    object({
      action: mixed().oneOf(['edit', 'delete']),
      cartype_feature_map: string().required('[Feature Map] 主要車種/次要車種為必填'),
      functional_feature_map: string().required('[Feature Map] 主要車種/次要車種為必填'),
      is_ondemand: number().required('[Feature Map] 行程類型為必填'),
    }),
  )
    .min(1, 'Feature Map 不能空白')
    .test('is-all-delete-action', 'Feature Map 不能空白', (value) => {
      return value.filter((row) => row.action === 'delete').length < value.length
    })
    .required('Feature Map 不能空白'),
  designated_daily_timeframe: array(
    object({
      id: string().notRequired(),
      time_of_day_end: string().when('id', {
        is: (value) => !value,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      time_of_day_start: string().when('id', {
        is: (value) => !value,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
  )
    .min(1, '[活動適用時段] 每日的時間區間不能空白')
    .notRequired(),
  designated_weekly_timeframe: array(
    object({
      hours_setting: array(object({ time_of_day_end: string().required(), time_of_day_start: string().required() })).min(
        1,
        '[活動適用時段] 每週的時間區間不能空白',
      ),
      day_of_week: object({
        0: number().required(),
        1: number().required(),
        2: number().required(),
        3: number().required(),
        4: number().required(),
        5: number().required(),
        6: number().required(),
      }),
      interval: number().required('[活動適用時段] 週頻率不能空白'),
    }),
  ).notRequired(),
  // amount: 50,
  // max_amount: null,
  // coupon_is_line_point_type: false,
  // line_point_coupon_settings: {
  //   feedback_amount: 0,
  //   feedback_ratio: 0,
  //   max_amount: null,
  // },
  // is_for_cash: 0,
  // minimum_charge: 0,
  // type: undefined,
  // category: undefined,
})

const useFormHelper = ({ feature = COUPON_FORM_FEATURES.CREATE, initialValues }) => {
  const [isLoadingSettings, setIsLoadingSettings] = useState(false)
  const [types, setTypes] = useState()
  const [categories, setCategories] = useState()

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoadingSettings(true)
        const [typeResp, categoryResp] = await Promise.all([getCouponTypes(), getCouponCategories()])
        if (typeResp.status !== 200) {
          throw new Error(typeResp.data.msg)
        }
        setTypes(typeResp.data)
        if (categoryResp.status !== 200) {
          throw new Error(categoryResp.data.msg)
        }
        setCategories(categoryResp.data)
      } catch (error) {
        Alert.error(error.message)
      }
    }
    fetch()
  }, [])

  const defaultValues = useMemo(() => generateDefaultValues(feature, initialValues), [feature, initialValues])

  return {
    settings: { loading: isLoadingSettings, types, categories },
    static: { promoTypeOptions, discountTypes, discountWays, lifespanTypes, carFunctionalTypes, carTypes, ondemandTypes, airportAreas },
    helper: {
      generateCode,
      dateTransform,
      formatDateTransform,
      numberTransform,
      featureMapTransform,
      locationTransform,
      filterFormValuesForSubmit,
    },
    defaultValues,
    schema,
  }
}

export default useFormHelper
